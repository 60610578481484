import React from 'react';
import { BsFillHandThumbsUpFill, BsHouseDoor, BsFillCheckCircleFill } from "react-icons/bs";
import { RiHandCoinLine, RiAuctionFill } from "react-icons/ri";
import { FaLaptopHouse, FaShoppingCart } from "react-icons/fa";
import { GiNotebook } from "react-icons/gi";
import Faq from "react-faq-component";
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";

const data = {
    title: <h1 className='pb-3 display-5 fw-bold title'>FAQ (How it works)</h1>,
    rows: [
        {
            title: <b>1. What Options I have for Building an Ecommerce Website?</b>,
            content: `When it comes to building out your ecommerce store, you’ll likely come across two options :<br />
            (a). Build your website from scratch (aka open source).<br />
            (b). Customize a SaaS (software-as-a-service) solution.`,
        },
        {
            title: <b>2. How Do I Choose the Right Ecommerce Development Firm?</b>,
            content: `It's Important that a Firm's Developer skills align with your ecommerce web design needs.<br />
            According to research,  73% of World consumers say customer experience is an important factor when purchasing online, yet only 49% believe brands provide a good experience.`,
        },
        {
            title: <b>3. Functionality To Consider When Developing An Ecommerce Website?</b>,
            content: `Different features and functionality you may want in your ecommerce site will vary based on your business model and unique needs.<br />
            Based on your set goals, create a list of features your ecommerce website needs. These should be closely aligned with your customer lifecycle.<br />
            For example, a startup or small business may not need to support multiple currencies, but this could be a crucial feature for a cross-border retailer.`,
        },
        {
            title: <b>4. How much money do I have to spend?</b>,
            content: `Costing depends a lot on the features you have in mind, the technology you want to build with, scalability and reliability. We take Guarantee in our experience and we deliver you the best value for your money.`,
        },
        {
            title: <b>5. What SEO best practices do you follow while building an ecommerce store?</b>,
            content: `SEO helps sites rank higher on Google and hence your online store must be developed keeping in mind the latest SEO norms. Ignoring SEO while developing your e-store will hamper its performance and growth in the long run.`,
        },
        {
            title: <b>6. Will my ecommerce store be easily scalable with my business?</b>,
            content: `Technology is everything when it comes to building an online store. It happens very often that entrepreneurs start their online stores with a solution that is not scalable with their business and later on have to rebuild everything. Hence, this is something that requires all the attention you can give. We at The Bridge Code understand this and will brainstorm and understand your Business needs and Develop a Solution which is Easily Scalable.`,
        },
    ],
};

const styles = {
    bgColor: 'white',
    titleTextColor: "#000",
    rowTitleColor: "#000",
    rowContentColor: '#000',
    arrowColor: "#000",
    transitionDuration: "1s",
};

const config = {
    animate: true,
    // arrowIcon: "V",
    tabFocus: true,
    expandIcon: "+",
    collapseIcon: "-",
};

// ================================|| EcommerceDevelopment ||================================ //

const EcommerceDevelopment = () => {
    return (
        <>
            <Helmet>
				<title>Ecommerce Website Development - The Bridge Code</title>
			</Helmet>
            <div className='container-fluid p-0'>
                <div class="position-relative overflow-hidden text-center ecomm">
                    <div class="col-md-7 p-lg-5 mx-auto my-1">
                        <h1 class="display-2 fw-bolder text-white title-c">eCommerce Website Development</h1>
                        <p class="lead fw-normal text-light">We bring e-commerce to your Doorstep</p>
                        <Link to='/contact-us'><button className='btn-change2'>Contact Us</button></Link>
                    </div>
                </div>
                <div className="container mt-5 mb-5">
                    <div className="row col-md-12">
                        <div className="col-md-6">
                            <div className="title"><h1>Overview</h1></div>
                            <p className="display-5">Have You Had Your Ecommerce Today?</p>
                            <p>Serving customers across the globe with its astounding set of eCommerce solutions. We tend to hype our growth to new heights in the upcoming years by
                                continuing to use the latest and advanced technology that helps us create competitive and futuristic solutions</p>
                            <p>Our E-Commerce services can alleviate the complex customer engagement structure and simplify the online shopping experience for your Clients. We develop
                                creatively fascinating web applications that bind your website visitors with efficient layout and accessibility. Even if you are selling in-demand goods at
                                the best prices, you will need an eCommerce website that reflects the core idea of your enterprise. Our top eCommerce website designers and application
                                developers are here to help you with that and everything else that can lead to boosted eCommerce sales and seamless growth.</p>
                            <ul className="">
                                <li className="list-group-item p-1 fs-5"><BsFillHandThumbsUpFill /> Full-cycle website design and development</li>
                                <li className="list-group-item p-1 fs-5"><BsFillHandThumbsUpFill /> Easy Checkout</li>
                                <li className="list-group-item p-1 fs-5"><BsFillHandThumbsUpFill /> Creative UI/UX</li>
                                <li className="list-group-item p-1 fs-5"><BsFillHandThumbsUpFill /> Maintenance and support</li>
                                <li className="list-group-item p-1 fs-5"><BsFillHandThumbsUpFill /> Mobile-driven development</li>
                            </ul>
                        </div>
                        <div className="col-md-6 align-self-center">
                            <img src="https://res.cloudinary.com/dm1muiikf/image/upload/v1726209808/website/ecomm/e_commerce.svg" alt="" className="img-fluid box bounce-1" />
                        </div>
                    </div>
                </div>

                <div className="container-fluid csd_serv">
                    <div className="container">
                        <div className="row text-center justify-content-between">
                            <h1 className="display-2 fw-bold text-light title-c mb-5">Technologies We Use</h1>

                            <div className="three rounded shadow-lg text-white pt-3 mt-5" style={{ backgroundColor: "rgb(0,0,0,0.3)" }}>
                                <div className="mb-3"><img src="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/csd/nopcommerce.png" className="img-fluid bg-body" width={96} alt="NopCommerce" /></div>
                                <h4>NopCommerce</h4>
                                <p className="text-light">NopCommerce is an open-source eCommerce platform based on Microsoft's ASP.NET Core framework. As an open-source platform,
                                    nopCommerce offers a flexible architecture that allows you to customize any process your company needs.</p>
                                <div className="row px-2">
                                    <h4 className="text-start title">Features</h4>
                                    <ul className="col-md-5 text-start">
                                        <li className="list-group-item p-1"><BsFillCheckCircleFill /> Facilitates rapid project development</li>
                                        <li className="list-group-item p-1"><BsFillCheckCircleFill /> Reduces compilation time</li>
                                    </ul>
                                    <ul className="col-md-6 text-start">
                                        <li className="list-group-item p-1"><BsFillCheckCircleFill /> Enhanced speed performance</li>
                                        <li className="list-group-item p-1"><BsFillCheckCircleFill /> Shorter codes</li>
                                        <li className="list-group-item p-1"><BsFillCheckCircleFill /> Extended Functionality</li>
                                    </ul>
                                </div>
                            </div>

                            <div className="three rounded shadow-lg text-white pt-3 mt-5" style={{ backgroundColor: "rgb(0,0,0,0.3)" }}>
                                <div className="mb-3"><img src="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/csd/shopify.png" className="img-fluid bg-body" width={155} alt="Shopify" /></div>
                                <h4>Shopify </h4>
                                <p className="text-light">Shopify is a user-friendly e-commerce platform that helps small businesses build an online store and sell online through one streamlined dashboard. Shopify merchants can build a modern online store and sell on social media sites, seller marketplaces, other blogs and websites and via email, text and chat.</p>
                                <div className="row px-2">
                                    <h4 className="text-start title">Features</h4>
                                    <ul className="col-md-5 text-start">
                                        <li className="list-group-item p-1"><BsFillCheckCircleFill /> Quick & easy to set up & use</li>
                                        <li className="list-group-item p-1"><BsFillCheckCircleFill /> No tech worries</li>
                                        <li className="list-group-item p-1"><BsFillCheckCircleFill /> Secure & reliable</li>
                                    </ul>
                                    <ul className="col-md-6 text-start">
                                        <li className="list-group-item p-1"><BsFillCheckCircleFill /> Customer support</li>
                                        <li className="list-group-item p-1"><BsFillCheckCircleFill /> Mobile ready</li>
                                    </ul>
                                </div>
                            </div>

                            <div className="three rounded shadow-lg text-white pt-3 mt-5" style={{ backgroundColor: "rgb(0,0,0,0.3)" }}>
                                <div className="mb-3"><img src="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/csd/woocommerce.png" className="img-fluid bg-body" alt="WooCommerce" /></div>
                                <h4>WooCommerce</h4>
                                <p className="text-light">WooCommerce is an open-source flexible software solution built for WordPress-based websites. It's commonly used to create online e-commerce shops. With this software solution, anyone can turn their regular website into a fully functioning online store, complete with all the necessary e-commerce features</p>
                                <div className="row px-2">
                                    <h4 className="text-start title">Features</h4>
                                    <ul className="col-md-5 text-start">
                                        <li className="list-group-item p-1"><BsFillCheckCircleFill /> Customizable Branding</li>
                                        <li className="list-group-item p-1"><BsFillCheckCircleFill /> Email Marketing</li>
                                        <li className="list-group-item p-1"><BsFillCheckCircleFill /> Inventory Management</li>
                                    </ul>
                                    <ul className="col-md-6 text-start">
                                        <li className="list-group-item p-1"><BsFillCheckCircleFill /> Inventory Optimization</li>
                                        <li className="list-group-item p-1"><BsFillCheckCircleFill /> Multi-Currency</li>
                                    </ul>
                                </div>
                            </div>

                            <div className="three rounded shadow-lg text-white pt-3 mt-5" style={{ backgroundColor: "rgb(0,0,0,0.3)" }}>
                                <div className="mb-3"><img src="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/csd/magento.png" className="img-fluid bg-body" width={96} alt="Hybrid Application Integration" /></div>
                                <h4>Magento</h4>
                                <p className="text-light"> Magento, an open source e-commerce platform which provides online selling enterprises with flexible e-commerce solutions. At
                                    Cynoteck Technology the developers have massive experience with a notable level of knowledge in Magento’s updated versions, that helps all your business
                                    needs like marketing, merchandising, shipping and wallet creation etc.</p>
                                <div className="row px-2">
                                    <h4 className="text-start title">Features</h4>
                                    <ul className="col-md-5 text-start">
                                        <li className="list-group-item p-1"><BsFillCheckCircleFill /> Customizable</li>
                                        <li className="list-group-item p-1"><BsFillCheckCircleFill /> SEO Friendly</li>
                                        <li className="list-group-item p-1"><BsFillCheckCircleFill /> calable</li>
                                    </ul>
                                    <ul className="col-md-6 text-start">
                                        <li className="list-group-item p-1"><BsFillCheckCircleFill /> Flexible</li>
                                        <li className="list-group-item p-1"><BsFillCheckCircleFill /> Omnichannel support</li>
                                        <li className="list-group-item p-1"><BsFillCheckCircleFill /> Mobile friendly features</li>
                                    </ul>
                                </div>
                            </div>

                            <div className="three rounded shadow-lg text-white pt-3 mt-5" style={{ backgroundColor: "rgb(0,0,0,0.3)" }}>
                                <div className="mb-3"><img src="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/csd/prestashop.png" className="img-fluid bg-body" width={156} alt="PrestaShop" /></div>
                                <h4>PrestaShop </h4>
                                <p className="text-light"> PrestaShop is a freemium, open source e-commerce platform. It is an online platform that allows users to create an e-commerce presence, enabling them to sell products and services through the web to an online audience.</p>
                                <div className="row px-2">
                                    <h4 className="text-start title">Features</h4>
                                    <ul className="col-md-5 text-start">
                                        <li className="list-group-item p-1"><BsFillCheckCircleFill /> Easy to install</li>
                                        <li className="list-group-item p-1"><BsFillCheckCircleFill /> Budget-Friendly</li>
                                        <li className="list-group-item p-1"><BsFillCheckCircleFill /> User-friendly</li>
                                    </ul>
                                    <ul className="col-md-6 text-start">
                                        <li className="list-group-item p-1"><BsFillCheckCircleFill /> Easy Customization</li>
                                        <li className="list-group-item p-1"><BsFillCheckCircleFill /> Demographic assistance</li>
                                    </ul>
                                </div>
                            </div>

                            <div className="three rounded shadow-lg text-white pt-3 mt-5" style={{ backgroundColor: "rgb(0,0,0,0.3)" }}>
                                <div className="mb-3"><img src="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/csd/wordpress.png" className="img-fluid bg-body" width={96} alt="Hybrid Application Integration" /></div>
                                <h4>WordPress</h4>
                                <p className="text-light"> Our WordPress developers can extremely transform your website’s user experience in a way that your visitors would become your
                                    promising marketing channel. According to the facts, 63% of websites that you see online are completely or partially made of WordPress.</p>
                                <div className="row px-2">
                                    <h4 className="text-start title">Features</h4>
                                    <ul className="col-md-5 text-start">
                                        <li className="list-group-item p-1"><BsFillCheckCircleFill /> Easy To Install And Setup</li>
                                        <li className="list-group-item p-1"><BsFillCheckCircleFill /> Easy To Use</li>
                                        <li className="list-group-item p-1"><BsFillCheckCircleFill /> Cost Effective And Cheap</li>
                                    </ul>
                                    <ul className="col-md-6 text-start">
                                        <li className="list-group-item p-1"><BsFillCheckCircleFill /> Search Engine And SEO Friendly</li>
                                        <li className="list-group-item p-1"><BsFillCheckCircleFill /> Easy, One-Click Updates</li>
                                        <li className="list-group-item p-1"><BsFillCheckCircleFill /> Most Plugins And App</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container p-5">
                    <h1 className="title-c">Our Ecommerce App Development Service Process</h1>
                    <div className="row text-center justify-content-between">
                        <div className="col-lg-3 col-md-6 col-sm-12 bg-body rounded shadow-lg mx-2 my-2 py-4">
                            <i className='boarder-2'><BsHouseDoor style={{ color: '#20B2AA' }} size="96" /></i>
                            <h4>Shopping Stores</h4>
                            <p>Being India’s top service providers for eCommerce website development, we make sure that your shopping store looks great on the web. We can create online
                                fashion, grocery, and electronics stores where you can connect with your buyers through exciting coupons and offers hassle-free. Our eCommerce website design
                                services let you sell your best products in India and overseas.</p>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12 bg-body rounded shadow-lg mx-2 my-2 py-4">
                            <i className='boarder-2'><FaLaptopHouse style={{ color: '#20B2AA' }} size="96" /></i>
                            <h4>eCommerce Marketplace</h4>
                            <p>Other than eCommerce shopping portals, our eCommerce web developers also build a multilingual marketplace for both b2b and b2c firms. No matter what region of
                                the world you are headquartered in, our eCommerce website development company can help you go global. Now you can meet suppliers and buyers worldwide through
                                a highly customized web portal.</p>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12 bg-body rounded shadow-lg mx-2 my-2 py-4">
                            <i className='border-2 pt-2'><GiNotebook style={{ color: '#20B2AA' }} size="96" /></i>
                            <h4>eBooking Portal</h4>
                            <p>eBooking is a powerful element of a future-ready business model that elevates numerous sectors such as tour & travel, hotels, fine-dining restaurants, etc. The top developers in our company can build robust and scalable portal. </p>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12 bg-body rounded shadow-lg mx-2 my-2 py-4">
                            <i className='boarder-2'><RiAuctionFill style={{ color: '#20B2AA' }} size="96" /></i>
                            <h4>Online Auction Platform</h4>
                            <p>Our eCommerce development agency understands that eCommerce is synonymous with convenience for all. This is the reason why we try to bring every aspect of
                                boundless trading online. Other than the eBooking and eCommerce marketplace, our professional team also develops online auction platforms for businesses.
                                These portals are customized for different bidding types and auction methods.</p>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12 bg-body rounded shadow-lg mx-2 my-2 py-4">
                            <i className='boarder-2'><RiHandCoinLine style={{ color: '#20B2AA' }} size="96" /></i>
                            <h4>B2B Trade Portal</h4>
                            <p>B2B trading is meticulously vast! Our eCommerce app development build your B2B portals with tailored features enabling vendors to unite with partners
                                effortlessly. With these platforms, we endeavor to simplify communication and trading amongst suppliers, manufacturers, retailers, and stakeholders.
                                Our B2B eCommerce services entangle the trading cycle in a secure way.</p>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12 bg-body rounded shadow-lg mx-2 my-2 py-4">
                            <i className='boarder-2'><RiHandCoinLine style={{ color: '#20B2AA' }} size="96" /></i>
                            <h4>Multi-Vendor Platform</h4>
                            <p>Are you looking for an eCommerce website design development company in India for multi-vendor portal development? If so, then The Bridge Code is here to help. We have the finest web developers who can configure multilingual features and functionalities, and integrate them into the Platform.</p>
                        </div>
                    </div>

                    <div className="col-md-7 p-lg-5 mx-auto  p-3 mt-5 text-white ecom rounded-3">
                        <p className='fs-1 fw-bold'>Our E-Commerce Sales Funnel:</p>
                        <p className='fw-bold fs-5 pb-2'>We believe in these 4 Stages for Success :</p>
                        <ul className='p-0'>
                            <li className='pb-2'><FaShoppingCart className='blink' size={20} style={{ color: "#283b8b" }} /><span className='fw-bolder'>&nbsp; Attract –</span> Get People to Your Website. The first goal of your e-commerce site is to get people to visit your store.</li>
                            <li className='pb-2'><FaShoppingCart className='blink' size={20} style={{ color: "#283b8b" }} /><span className='fw-bolder'>&nbsp; Convert – </span>Get People to Want to buy.</li>
                            <li className='pb-2'><FaShoppingCart className='blink' size={20} style={{ color: "#283b8b" }} /><span className='fw-bolder'>&nbsp; Close – </span>Get Them to Complete the Checkout Process.</li>
                            <li className='pb-2'><FaShoppingCart className='blink' size={20} style={{ color: "#283b8b" }} /><span className='fw-bolder'>&nbsp; Retain – </span>Get Them to Come Back and Buy More.</li>
                        </ul>
                    </div>
                </div>

                {/* <!-- Cta--> */}
                <div className="container-fluid p-0 m-0">
                    <div className="parallax-container py-5">
                        <div className="parallax-content section-xl">
                            <div className="container">
                                <div className="row justify-content-end text-right">
                                    <div className="col-sm-7 text-center">
                                        <h3 className="display-4 fw-bold text-white mb-5">Why The Bridge Code?</h3>
                                        <p className='text-light mb-4'>The Bridge Code Technologies is a Firm where we provide software development, integrations, and resources you need to compete
                                            in the Fast changing world. We believe in the power of marketing, sales, content management and great customer service are key to success in any
                                            Business. We have started a New Approach for our Clients where they can see the Potential of our Skills and Software. Try our Free Demo! </p>
                                        <p className='mt-3 text-white fs-4 fw-bold'>If you have the Passion, We have the Plan!!</p>
                                        <div className="mt-4">
                                            <Link to='/solution' className="cl-button -primary -small mx-1">Try Free Demo</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container pt-5 pb-5">
                    <div className="col-md-12 ">
                        <div className="col-md-8 mx-auto">
                            <Faq data={data} styles={styles} config={config} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default EcommerceDevelopment;