import React from 'react';
import { BsFillArrowRightCircleFill, BsMenuAppFill, BsGlobe, BsCart4, BsPeopleFill } from "react-icons/bs";
import { AiOutlineFileProtect, AiFillAndroid, } from "react-icons/ai";
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";

// ================================|| Services ||================================ //

function Services() {
	return (
		<>
			<Helmet>
				<title>Services - The Bridge Code</title>
			</Helmet>
			<div className='p-0 container-fluid'>
				<div className="position-relative overflow-hidden text-center services">
					<div className="col-md-7 p-lg-5 mx-auto my-5">
						<h1 className="display-2 fw-bolder text-white pb-4 title-c">Our Services</h1>
						<p className="lead fw-normal text-light">SOFTWARE DEVELOPMENT SERVICES WE OFFER</p>
						<Link to='/contact-us' class="cl-button -primary -big rounded-3">Contact Us</Link>
					</div>
				</div>

				<div className="container mt-5 mb-5">
					<div className="row col-md-12">
						<div className="col-md-6">
							<div className="title"><h1>Overview</h1></div>
							<p className="display-5">Your Partner For Innovations!</p>
							<p className="fs-3 fw-bold">We Brainstorm and Develop according to your Business Needs.</p>
							<p>Before selecting the right tool and software for your organization, you also need to find the right technology partner who can offer the best services in the market. The Bridge Code Technology is a leading Software Development company offering services for various Industries. Our professional team is passionate about creating all types of customized solutions and providing a comprehensive set of end-to-end IT Services.</p>
							<p>Tap into a team of specialists with unique expertise in Different technologies, content process automation, multilingual knowledge management and language strategies with unique industry knowledge.</p>
						</div>
						<div className="col-md-6 align-self-center">
							<img src="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/hire_deve/team_hire.gif" alt="" className="img-fluid" width={'100%'} />
						</div>
					</div>
				</div>

				{/* rgb(172 193 207) */}
				<div className="container-fluid p-0 mb-3 service" style={{ backgroundImage: "linear-gradient(to right, #0089eb, #65c0e7)", }}>
					<div className='circle xlarge shade2'></div>
					<div className='circle large shade3'></div>
					<div className='circle medium shade4'></div>
					<div className='circle small shade5'></div>
					<div className="container pb-2">
						<div className="col-md-12 ">
							<h1 className='title-c'>Utilize the premium software <br /> development experience</h1>
							<div className="main-timeline">
								<div className=" timeline">
									<div className="timeline-icon"><i className='zoom'><AiOutlineFileProtect /></i></div>
									<div className="timeline-content shadow-sl">
										<h3 className="title text-dark">Website Development</h3>
										<p className="description mb-2">We Develop website that are simply Impossible to Ignore.</p>
										<p className='description mb-3'>Our skillful developers build Responsive Website for assisting you in leveraging the vision of a technical solution.
											Also, they do it to help you drive more value to your business so you stay ahead from your competitors with a competitive edge.
										</p>
										<Link to='/website-development' className="cl-button -primary -small mt-1 mb-2" style={{ position: 'relative', cursor: 'pointer' }}>View More <BsFillArrowRightCircleFill /></Link>
									</div>
								</div>
								<div className="timeline hover-shadow">
									<div className="timeline-icon"><i className='zoom'><AiFillAndroid /></i></div>
									<div className="timeline-content shadow-sl">
										<h3 className="title text-dark">Mobile Application Development</h3>
										<p className='description mb-3'>Usage of mobile apps has become increasingly prevalent across mobile phone users. The ubiquity and level of interaction people have with
											mobile devices means that nearly everyone has an idea for a mobile app. Mobile devices open up a whole new way to interact with computing,
											the web, and even corporate infrastructure.
										</p>
										<Link to='/mobile-application-development' className="cl-button -primary -small mt-1 mb-2" style={{ position: 'relative', cursor: 'pointer' }}>View More <BsFillArrowRightCircleFill /></Link>
									</div>
								</div>
								<div className="timeline">
									<div className="timeline-icon"><i className='zoom'><BsMenuAppFill /></i></div>
									<div className="timeline-content shadow-sl">
										<h3 className="title text-dark">Hybrid APP Development</h3>
										<p className="description mb-3">Hybrid app development is marching ahead and is the Future of Development. We have Experienced Developers
											who has successfully delivered Cross-Platform Apps which can bring value to your Business at Highly Reduced Costs.</p>
										<Link to='/hybridapp-development' className="cl-button -primary -small mt-1 mb-2" style={{ position: 'relative', cursor: 'pointer' }}>View More <BsFillArrowRightCircleFill /></Link>
									</div>
								</div>
								<div className="timeline">
									<div className="timeline-icon"><i className='zoom'><BsGlobe /></i></div>
									<div className="timeline-content shadow-sl">
										<h3 className="title text-dark">Custom Software Development</h3>
										<p className="description mb-3">
											Unique Solution that Belongs to Your Business Only. The biggest reason for developing custom software is your company owns the solution. We Develop applications specially designed to meet the specific requirements of your organizations Software users.
										</p>
										<Link to='/custom-software-development' className="cl-button -primary -small mt-1 mb-2" style={{ position: 'relative', cursor: 'pointer' }}>View More <BsFillArrowRightCircleFill /></Link>
									</div>
								</div>
								<div className="timeline">
									<div className="timeline-icon"><i className='zoom'><BsCart4 /></i></div>
									<div className="timeline-content shadow-sl">
										<h3 className="title text-dark">E-Commerce Development</h3>
										<p className='description'>We Create your Online Wholesale/Retail Business Needs.</p>
										<p className='description mb-3'>We have Successfully Developed & Designed various solution for our Customer to make them compete in the marketplace despite of their
											businesses Size. Multiple payment integration, a powerful CMS, with 24/7 support and maintenance services ensures your eCommerce store
											is the most popular one across the globe.</p>
										<Link to='/e-commerce-development' className="cl-button -primary -small mt-1 mb-2" style={{ position: 'relative', cursor: 'pointer' }}>View More <BsFillArrowRightCircleFill /></Link>
									</div>
								</div>
								<div className="timeline">
									<div className="timeline-icon"><i className='zoom'><BsPeopleFill /></i></div>
									<div className="timeline-content shadow-sl">
										<h3 className="title text-dark">HIRE Dedicated Resource - HDR</h3>
										<p className="description mb-2"><b></b></p>
										<p className="description">WHERE OUR DEVELOPER WILL WORK IN YOUR PROJECT ONLY</p>
										<p className="description mb-3">Now-a-days to cut the Designing and Development Cost Clients across the Globe HIRE Dedicated Developers where the
											Developers will be Dedicatedly work on the Client Project and will never switch to another project. This way Clients get Best Solution on their Hand and can Compete with a Stronger Product in market.</p>
										<Link to='/hire-dedicated-resources' className="cl-button -primary -small mt-1 mb-2" style={{ position: 'relative', cursor: 'pointer' }}>View More <BsFillArrowRightCircleFill /></Link>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				{/* <!-- Cta--> */}
				<div className="container-fluid p-0 m-0">
					<div className="parallax-container py-5">
						<div className="parallax-content section-xl">
							<div className="container">
								<div className="row justify-content-end text-right">
									<div className="col-sm-7 text-center">
										<h3 className="display-4 fw-bold text-white mb-5">Why The Bridge Code?</h3>
										<p className='text-light mb-4'>The Bridge Code Technologies is a Firm where we provide software development, integrations, and resources you need to compete
											in the Fast changing world. We believe in the power of marketing, sales, content management and great customer service are key to success in any
											Business. We have started a New Approach for our Clients where they can see the Potential of our Skills and Software. Try our Free Demo! </p>
										<p className='mt-3 text-white fs-4 fw-bold'>If you have the Passion, We have the Plan!!</p>
										<div className="mt-4">
											<Link to='/solution' className="cl-button -primary -small mx-1">Try Free Demo</Link>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Services;