import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import { scrollToTop } from "../utils/utilityFunctions";

<head>
	<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css" />
</head>

// ================================|| Header ||================================ //

const Header = () => {
	const [offset, setOffset] = useState(0);

	useEffect(() => {
		const onScroll = () => setOffset(window.pageYOffset);
		document.getElementById("navbar").style.display = "none";
		return () => window.removeEventListener('scroll', onScroll);
	}, []);

	window.onscroll = function () { scrollFunction() };
	const scrollFunction = () => {
		if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
			document.getElementById("navbar").style.top = "";
			document.getElementById("navbar").style.display = "block";
		}
		else {
			document.getElementById("navbar").style.top = "-100%";
			document.getElementById("navbar").style.display = "block";
		}
	}

	const handleNavLinkClick = ()=>{
		scrollToTop()
		const navbarToggle = document.querySelector('.navbar-toggler');
		if (navbarToggle && window.getComputedStyle(navbarToggle).display !== 'none' && (navbarToggle.getAttribute('aria-expanded') == "true")) {
			navbarToggle.click();
		}
	}

	return (
		<>
			<nav className="container-fluid navbar navbar-expand-lg bg-body" id="navbar" style={{ boxShadow: "0px 5px #65c0e7", zIndex: "999999" }}>
				<div className="container mt-1">
					<div className="d-flex" id="mobile">
						<Link to="/" onClick={handleNavLinkClick} ><img src="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/TBC-logo.png" className="img-fluid" alt="The Bridge Code" /> </Link>
					</div>
					<button
						className="navbar-toggler mt-3"
						type="button"
						data-bs-toggle="collapse"
						data-bs-target="#navbarSupportedContent"
						aria-controls="navbarSupportedContent"
						aria-expanded="false"
						aria-label="Toggle navigation"
					>
						<span className="navbar-toggler-icon"></span>
					</button>

					<div className="header collapse navbar-collapse mx-auto" id="navbarSupportedContent">
						<ul className="nav-links navbar-nav justify-content-center">
							<li className="nav-item mx-2 my-auto text-lg-center py-1" activeStyle>
								<Link to="/" onClick={handleNavLinkClick} className='text-decoration-none text-center mx-xl-2 py-1'>Home</Link>
							</li>
							<li className="nav-item dropdown text-center my-auto text-lg-center py-1">
								<a className="nav-link dropdown-toggle mx-2 py-0" href="#" id="navbarDarkDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
									About
								</a>
								<ul className="dropdown-menu" aria-labelledby="About" >
									<li><Link to="/about-us"  onClick={handleNavLinkClick} className='dropdown-item text-decoration-none'>About Us</Link></li>
									<li><Link to="/career"  onClick={handleNavLinkClick} className='dropdown-item text-decoration-none'>Career</Link></li>
                                	{/* <li><Link to="/blogs"  onClick={handleNavLinkClick} className='dropdown-item text-decoration-none'>Blog</Link></li> */}
								</ul>
							</li>
							<li className="nav-item mx-2 my-auto text-lg-center py-1" activeStyle>
								<Link to="/services"  onClick={handleNavLinkClick} className='text-decoration-none text-center mx-xl-2'>Services</Link>
							</li>
							<li className="nav-item mx-2 my-auto text-lg-center py-1" activeStyle>
								<Link to="/portfolio"  onClick={handleNavLinkClick} className='text-decoration-none text-center mx-xl-2'>Portfolio</Link>
							</li>
							<li className="nav-item mx-2 my-auto text-lg-center py-1" activeStyle>
								<Link to="/solution"  onClick={handleNavLinkClick} className='text-decoration-none text-center mx-xl-2'>Solution</Link>
							</li>
							<li className="nav-item mx-2 my-auto text-lg-center py-1" activeStyle>
								<Link to="/case-study"  onClick={handleNavLinkClick} className='text-decoration-none text-center mx-xl-2'>Case Study</Link>
							</li>
							<li className="nav-item mx-2 my-auto text-lg-center py-1" activeStyle>
								<Link to="/blogs" onClick={handleNavLinkClick} className='text-decoration-none text-center mx-xl-2 py-1'>Blogs</Link>
							</li>
							<li className="nav-item mx-2 my-auto text-lg-center py-1" activeStyle>
								<Link to="/contact-us"  onClick={handleNavLinkClick} className='text-decoration-none text-center mx-xl-2'>Contact Us</Link>
							</li>
							<li className="nav-item mx-3 my-lg-auto text-lg-center" >
								<a className="cl-button -primary -big text-white" href='/#quotation'>Get Free Quotation</a>
							</li>
						</ul>
					</div>
				</div>
			</nav>
		</>
	);
};

export default Header;