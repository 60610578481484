import React from 'react';
import { useState } from 'react';
import { Modal } from 'react-bootstrap';
import API from '../../../middleware/API';
import { Helmet } from "react-helmet";

// ================================|| Contact ||================================ //

const Contact = () => {
	const [FullName, setFullName] = useState("");
	const [Company, setCompany] = useState("");
	const [Title, setTitle] = useState("");
	const [Email, setEmail] = useState("");
	const [MobileNo, setMobileNo] = useState("");
	const [Image, setImage] = useState("");
	const [Description, setDescription] = useState("");
	const [message, setMessage] = useState("");
	const [Show, setShow] = useState(false);
	const handleClose = () => setShow(false);               // Open model close on click on a close button.
	const handleShow = () => setShow(true);                 // On button click Open the model.

	let handleSubmit = async (e) => {
		e.preventDefault();
		try {
			var SignuploadformAPI = API.signuploadform;
			const signResponse = await fetch(SignuploadformAPI);
			const signData = await signResponse.json();
			const url = "https://api.cloudinary.com/v1_1/" + signData.cloudname + "/auto/upload";
			const files = document.querySelector("[type=file]").files;
			let file = files[0];
			const formData = new FormData();

			formData.append("file", file);
			formData.append("api_key", signData.apikey);
			formData.append("timestamp", signData.timestamp);
			formData.append("signature", signData.signature);
			formData.append("eager", "c_pad,h_300,w_400|c_crop,h_200,w_260");
			formData.append("folder", "website");

			const fileData = await fetch(url, {
				method: "POST",
				body: formData
			})
			const fileJson = await fileData.json();
			console.log(fileJson);

			var SendContactDetailEmailAPI = API.sendContactDetailEmail;
			let result = await fetch(SendContactDetailEmailAPI, {
				method: "POST",
				headers: {
					'Accept': 'application/json',
					'Content-type': 'application/json',
				},
				json: true,
				body: JSON.stringify({
					fullName: FullName,
					email: Email
				}),
			});
			let resJsons = await result.json();
			if (resJsons.status === "SUCCESS") {
				var SaveContactDetailAPI = API.saveContactDetail;
				let res = await fetch(SaveContactDetailAPI, {
					method: "POST",
					headers: {
						'Accept': 'application/json',
						'Content-type': 'application/json',
					},
					json: true,
					body: JSON.stringify({
						fullName: FullName,
						company: Company,
						title: Title,
						email: Email,
						mobileNo: MobileNo,
						image: fileJson.secure_url,
						description: Description,
					}),
				});
				if (res.status === 200) {
					handleShow();
					setFullName("");
					setCompany("");
					setTitle("");
					setEmail("");
					setCompany("");
					setMobileNo("");
					setImage("");
					setDescription("");
					setMessage("Your response has been submitted sucessfully.");
				}
			}
		} catch (error) {
			console.log(error);
		}
	};

	return (
		<>
			<Helmet>
				<title>Contact Us - The Bridge Code</title>
			</Helmet>
			<Modal show={Show} style={{ marginTop: "300px" }}>
				<Modal.Header>
					<Modal.Title style={{ fontSize: "1.3rem" }}>
						Your response has been submitted sucessfully.<br />
						<input type="submit" value="Ok" style={{ width: "fit-content", marginLeft: "200px" }} onClick={handleClose} className="cl-button -primary -small rounded-2 py-2" />
					</Modal.Title>
				</Modal.Header>
			</Modal>

			<div className='p-0 container-fluid'>
				<section className='row m-0 contacts d-flex align-items-center justify-content-center'>
					<h1 className='pt-5 mt-5 text-center display-1 pb-4 title-c'>Contact Us</h1>
					<div className="contact-box justify-content-center">
						<div className="contact-links ">
							<h2 className='display-3 pb-4'>Enquire <span className='text-warning fw-bold'>Now!</span></h2>
							<p className='pb-4'>We are creative IT Company offering a full-service Software Development solutions to the peoples who need it. Our Outstanding experience maximize your business.</p>
							<hr />
							<div className='row pt-3'>
								<div className='col-md-2'><img src='https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/location.png' width={45} className="bg-body rounded-5 p-1" /></div>
								<div className='col-md-10 text-center text-md-start'><b>Address:</b> Ahmedabad, Gujarat,India.</div>
							</div>
							<hr />
							<div className='row'>
								<div className='col-md-2'><img src='https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/call.png' width={45} className="bg-body rounded-5 p-1" /></div>
								<div className='col-md-10 text-center text-md-start'><b>Lets Talk: </b>+91 79 84909172</div>
							</div>
							<hr />
							<div className='row'>
								<div className='col-md-2'><img src='https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/mail.png' width={45} className="bg-body rounded-5 p-1" /></div>
								<div className='col-md-10 text-center text-md-start'><b>Email: </b> info@thebridgecode.com</div>
							</div>
						</div>
						<div className="contact-form-wrapper ">
							<form action="Tbl_TBC_Contacts" method="POST" onSubmit={handleSubmit} id="contact-us-form"> {/* https://formspree.io/f/xeqwzqld */} {/* ref={form} onSubmit={sendEmail} */}
								<div className="form-item pb-4">
									<input type="text" name="fname" value={FullName} onChange={(e) => setFullName(e.target.value)} required />
									<label className='labe'>Full Name <span className='text-danger'>*</span></label>
								</div>
								<div className='row m-0'>
									<div className="col-md-6 p-0">
										<div className="form-item pb-4 me-md-2">
											<input type="text" name="company" value={Company} onChange={(e) => setCompany(e.target.value)} required />
											<label className='labe'>Company <span className='text-danger'>*</span></label>
										</div>
									</div>
									<div className="col-md-6 p-0">
										<div className="form-item pb-4 ms-md-2">
											<input type="text" name="title" value={Title} onChange={(e) => setTitle(e.target.value)} required />
											<label className='labe'>Title <span className='text-danger'>*</span></label>
										</div>
									</div>
								</div>
								<div className="form-item pb-4">
									<input type="email" name="email" value={Email} onChange={(e) => setEmail(e.target.value)} required />
									<label className='labe'>Email <span className='text-danger'>*</span></label>
								</div>
								<div className="form-item pb-4">
									<input type="number" name="mobile_no" value={MobileNo} onChange={(e) => setMobileNo(e.target.value)} required />
									<label className='labe'>Mobile No <span className='text-danger'>*</span></label>
								</div>
								<div className="form-item pb-4">
									<input type="file" name='file' className="bg-body text-dark form-control-file" value={Image} onChange={(e) => setImage(e.target.value)} />
								</div>
								<div className="form-item pb-4">
									<textarea name="message" value={Description} onChange={(e) => setDescription(e.target.value)} required></textarea>
									<label className='labe'>Message <span className='text-danger'>*</span></label>
								</div>
								<button type="submit" className="cl-button -primary -small px-4 rounded-3 text-center" value="Submit" >Send</button>
								<div className="message">{message ? <p>{message}</p> : null}</div>
							</form>
							<hr style={{ height: "5px", background: "linear-gradient(45deg, #65c0e7, #0089eb, #65c0e7)" }} />
							<div className="links">
								{/* <div className="link">
									<a href="https://www.instagram.com/thebridgecode/" className="" target={'_blank'}><FaInstagram className='bg-body rounded-2' style={{ color: "#283b8b" }} size={'2.5rem'} /></a>
								</div>
								<div className="link">
									<a href="https://join.skype.com/invite/dqpa9PFcZ1aI" className="" target={'_blank'}><FaSkype className='bg-body rounded-2' style={{ color: "#283b8b" }} size={'2.5rem'} /></a>
								</div>
								<div className="link">
									<a href="https://www.linkedin.com/company/the-bridge-code/" className="" target={'_blank'}><FaLinkedin className='bg-body rounded-2' style={{ color: "#283b8b" }} size={'2.5rem'} /></a>
								</div>
								<div className="link">
									<a href="https://wa.me/message/2WJS2ZS4J2INL1" target={'_blank'} className=""><FaWhatsapp className='bg-body rounded-2' style={{ color: "#283b8b" }} size={'2.5rem'} /></a>
								</div>
								<div className="link">
									<a href="https://www.facebook.com/thebridgecode/" className="" target={'_blank'}><FaFacebook className='bg-body rounded-2' style={{ color: "#283b8b" }} size={'2.5rem'} /></a>
								</div>
								<div className="link">
									<a href="https://twitter.com/thebridgecode" target={'_blank'} className=""><FaTwitter className='bg-body rounded-2' style={{ color: "#283b8b" }} size={'2.5rem'} /></a>
								</div> */}
								<div className="link">
									<a href="https://www.instagram.com/thebridgecode/" className="" target={'_blank'}><img src='https://res.cloudinary.com/dm1muiikf/image/upload/v1704114953/website/footer_p/icons8-instagram.png' className='rounded-2' /></a>
								</div>
								<div className="link">
									<a href="https://join.skype.com/invite/dqpa9PFcZ1aI" className="" target={'_blank'}><img src='https://res.cloudinary.com/dm1muiikf/image/upload/v1704114953/website/footer_p/icons8-skype.png' className='rounded-2' /></a>
								</div>
								<div className="link">
									<a href="https://www.linkedin.com/company/the-bridge-code/" className="" target={'_blank'}><img src='https://res.cloudinary.com/dm1muiikf/image/upload/v1704114953/website/footer_p/icons8-linkedin.png' className='rounded-2' /></a>
								</div>
								<div className="link">
									<a href="https://wa.me/message/2WJS2ZS4J2INL1" target={'_blank'} className=""><img src='https://res.cloudinary.com/dm1muiikf/image/upload/v1704114953/website/footer_p/icons8-whatsapp.png' className='rounded-2' /></a>
								</div>
								<div className="link">
									<a href="https://www.facebook.com/thebridgecode/" className="" target={'_blank'}><img src='https://res.cloudinary.com/dm1muiikf/image/upload/v1704114953/website/footer_p/icons8-facebook.png' className='rounded-2' /></a>
								</div>
								<div className="link">
									<a href="https://twitter.com/thebridgecode" target={'_blank'} className=""><img src='https://res.cloudinary.com/dm1muiikf/image/upload/v1704114953/website/footer_p/icons8-twitterx.png' className='rounded-2' /></a>
								</div>
								<div className="link">
									<a href="https://t.snapchat.com/sYP1935x" target={'_blank'} className=""><img src='https://res.cloudinary.com/dm1muiikf/image/upload/v1704716673/website/footer_p/icons8-snapchat.png' height={42} width={42} className='rounded-2' /></a>
								</div>
							</div>
						</div>
					</div>
				</section>

				<div className='py-5'>
					<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3671.7874375845354!2d72.55873031535411!3d23.03157562177026!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e852e57fbe70d%3A0x736700e2c68362b4!2sThe%20Bridge%20Code!5e0!3m2!1sen!2sin!4v1678353976050!5m2!1sen!2sin"
						width="100%"
						height="450"
						style={{ border: 0 }}
						allowFullScreen=""
						loading="lazy"
						referrerpolicy="no-referrer-when-downgrade"></iframe>
				</div>
			</div>
		</>
	);
};

export default Contact;