import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useCookies } from "react-cookie";
import API from '../../../middleware/API';
import { Link, useNavigate } from 'react-router-dom';

// ================================|| ModalDialog ||================================ //

function ModalDialog() {
    const [Show, setShow] = useState(false);
    const [formState, setFormState] = useState({});
    const handleClose = () => setShow(false);               // Open model close on click on a close button.
    const handleShow = () => setShow(true);                 // On button click Open the model.
    const navigate = useNavigate();
    const [message, setMessage] = useState("");
    const [mailMessage, setmailMessage] = useState("");

    const onChangeHandler = (event) => {
        const fname = event.target.fname;
        const lname = event.target.lname;
        // const value = event.target.value;
        // const textarea = event.target.textarea;
        setFormState({ ...formState, [event.target.name]: event.target.value });
    };

    const [cookies, setCookie, removeCookie] = useCookies(['currentotp']);
    // Form details Handling      
    const submitHandler = async (event) => {
		event.preventDefault();
        const otp = Math.floor(100000 + Math.random() * 900000);
        var x = document.getElementById("otp");             // Id of OTP textbox's entire div , to hide or show OTP block                       --  Vishal(23-03-2023) 
        var y = document.getElementById("new_otp");         // Input textbox's id to get OTP match                              -- Vishal(23-03-2023)
        var email = event.target.elements.email.value;
        var store = cookies.currentotp;
        var selected = document.getElementById("select");
        var body = "";

        let subject = "This is My equiry form";
        if (selected.value === "POS") {
            body = "<br /><br /> Hi " + formState.name + ",<br />We are delighted to see you sign up for a free trial. You are just one step away from experiencing our world-class software." +
                "<br /><br />Your OTP is : <b>" + otp + "</b><br /><br />Login Credencials:<br />Username: admin@thebridgecode.com<br />Password: admin@123<br /><br />Thank You,<br />Team The Bridge Code."
        }
        else if (selected.value === "CRM Demo") {
            body = "<br /><br /> Hi " + formState.name + ",<br />We are delighted to see you sign up for a free trial. You are just one step away from experiencing our world-class software." +
                "<br /><br />Your OTP is : <b>" + otp + "</b><br /><br />Login Credencials:<br />Username: TBC1<br />Password: SuperAdmin@123<br /><br />Thank You,<br />Team The Bridge Code."
        }
        else if (selected.value === "Account Management") {
            body = "<br /><br /> Hi " + formState.name + ",<br />We are delighted to see you sign up for a free trial. You are just one step away from experiencing our world-class software." +
                "<br /><br />Your OTP is : <b>" + otp + "</b><br /><br />Login Credencials:<br />Role: Super Admin<br />Username: ACC101<br />Password: Admin<br /><br />Role: Accountant<br />Username: ACC102<br />Password: Accountant@123<br /><br />Role: Employee<br />Username: ACC103<br />Password: Employee@123<br /><br />Role: Manager<br />Username: ACC104<br />Password: Manager@123<br /><br />Thank You,<br />Team The Bridge Code."
        }
        else if (selected.value === "Nop-Commerce Website") {
            body = "<br /><br /> Hi " + formState.name + ",<br />We are delighted to see you sign up for a free trial. You are just one step away from experiencing our world-class software." +
                "<br /><br />Your OTP is : <b>" + otp + "</b><br /><br />Login Credencials:<br />Username: admin@thebridgecode.com<br />Password: admin@123<br/><br />Thank You,<br />Team The Bridge Code."
        }
        else if (selected.value === "E-Commerce Website") {
            body = "<br /><br /> Hi " + formState.name + ",<br />We are delighted to see you sign up for a free trial. You are just one step away from experiencing our world-class software." +
                "<br /><br />Your OTP is : <b>" + otp + "</b><br/><br />Thank You,<br />Team The Bridge Code."
        }

        if (x.style.display != "block") {
            x.style.display = "block";
            y.setAttribute('required', '');

            setmailMessage("Note: If you don't Find OTP email in your Inbox then Check your Spam folder.");
        }

        if (store == null || store == "" || store == "undefined") {
            var SendOTPEmailAPI = API.sendOTPEmail;
            let result = await fetch(SendOTPEmailAPI, {
                method: "POST",
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                },
                json: true,
                body: JSON.stringify({
                    email: email,
                    subject: subject,
                    bodyData: body
                }),
            });
            let resJsons = await result.json();
            if (resJsons.status === "SUCCESS") {
                setCookie('currentotp', otp, { path: '/' })
            }
        }
        else {
            var selected = document.getElementById("select");
            if (y.value == store) {

                try {
                    var SaveDemoProjectDetailAPI = API.saveDemoProjectDetail;
                    let res = fetch(SaveDemoProjectDetailAPI, {
                        method: "POST",
                        headers: {
                            'Accept': 'application/json',
                            'Content-type': 'application/json',
                        },
                        json: true,
                        body: JSON.stringify({
                            fullName: formState.name,
                            email: email,
                            projectName: selected.value
                        }),
                    });
                    let resJson = res.json();
                    if (resJson.status === 200) {
                    } else {
                    }
                } catch (error) {
                    console.log(error);
                }
                setMessage("Thank you for requesting a Project Demo.");

                // This is for the option selection for the demo
                if (selected.value === "POS") {
                    window.open("https://pos.thebridgecode.com/", '_blank');
                }
                else if (selected.value === "CRM Demo") {
                    window.open("https://crm.thebridgecode.com/", '_blank');
                }
                else if (selected.value === "Account Management") {
                    window.open("https://account.thebridgecode.com/", '_blank');
                }
                else if (selected.value === "Nop-Commerce Website") {
                    window.open("https://185.196.21.59:451/", '_blank');
                }
                else if (selected.value === "E-Commerce Website") {
                    window.open("https://tftweb.thebridgecode.com/", '_blank');
                }
                else {
                    return "error................";
                }

                removeCookie('currentotp', '', { path: '/' });                  // For the cookies clear take the usecookies id and path
                navigate("/solution");
                handleClose();
                setMessage("");
            }
            else {
                alert("Error....")
            }
        }
    }

    return (
        <>
            <Button className="cl-button -primary -small mx-1 px-3 rounded-2" variant="success" onClick={handleShow} >
                Try Free Demo
            </Button>

            <Modal show={Show} style={{ marginTop: "100px" }}>
                <Modal.Header closeButton onHide={handleClose}>
                    <Modal.Title>Select Your Demo Project</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form id='myform' onSubmit={submitHandler}>
                        <div className="form-item pb-4">
                            <input type="text" name="name" id='name' onChange={onChangeHandler} value={formState.name || ''} required />
                            <label className='labe' required>Full Name:</label>
                        </div>
                        <div className="form-item pb-4">
                            <input type="email" name="email" id='email' required />
                            <label className='labe'>Email:</label>
                        </div>
                        <div className="form-item pb-4">
                            <select class="form-select form-select-lg mb-3" id='select' required>
                                <option value="" selected>Select</option>
                                <option value="POS" >POS</option>
                                <option value="CRM Demo" >CRM Demo</option>
                                <option value="Account Management" >Account Management</option>
                                <option value="Nop-Commerce Website" >Nop-Commerce Website</option>
                                <option value="E-Commerce Website" >E-Commerce Website</option>
                            </select>
                        </div>
                        <div className="form-item pb-4" id='otp' style={{ display: "none" }}>
                            <input type="text" name="otp" id='new_otp' />
                            <label className='labe'>OTP</label>
                        </div>
                        <div>{mailMessage ? <p>{mailMessage}</p> : null}</div>
                        <input type="submit" value="Submit" id='get' className="cl-button -primary -small rounded-2 py-2" />
                        <div className="message">{message ? <p>{message}</p> : null}</div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <p className='text-muted' style={{ fontSize: "14px" }}>By submitting this request, you are agreeing to The Bridge Code<Link to='/privacy-policy'> Privacy Policy</Link> and <Link to='terms-of-use'>Terms of Service</Link></p>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default ModalDialog;