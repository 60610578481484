import React from "react";
import 'react-multi-carousel/lib/styles.css';
import { BsFillArrowRightCircleFill } from "react-icons/bs";
import { FaBuilding, FaEnvelopeOpenText, FaDesktop, FaRegCalendarCheck, FaSignal, FaBookReader, FaCloudflare, FaUsers, FaLink } from "react-icons/fa";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

const responsive = {
	desktop: {
		breakpoint: { max: 3000, min: 1024 },
		items: 3,
		slidesToSlide: 4 // optional, default to 1.
	},
	tablet: {
		breakpoint: { max: 1024, min: 464 },
		items: 2,
		slidesToSlide: 2 // optional, default to 1.
	},
	mobile: {
		breakpoint: { max: 464, min: 0 },
		items: 1,
		slidesToSlide: 1 // optional, default to 1.
	}
};

// ================================|| About ||================================ //

const About = () => {
	return (
		<>	
			<Helmet>
				<title>About Us - The Bridge Code</title>
			</Helmet>
			<div className='container-fluid p-0'>
				<div class="position-relative overflow-hidden text-center about">
					<div class="col-md-5 p-lg-5 mx-auto my-1">
						<h1 class="display-2 fw-bolder text-white title-c">About Us</h1>
						<p class="lead fw-normal text-light">Let's get to know about our Journey.</p>
						<Link to='/contact-us' class="cl-button -primary -big rounded-3">Contact Us</Link>
					</div>
				</div>

				<div className="container mb-5 mt-5" data-aos="fade-up">
					<div className="row col-md-12 align-items-center">
						<div className="col-md-4 d-flex justify-content-center">
							<a className="me-4 thumbnail shadow-sl">
								<img src="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/abt-side.jpg" alt="Side" className="img-fluid" />
							</a>
						</div>
						<div className="col-md-8 ps-md-5 mt-4 mt-md-0">
							<h4 className="mt-4">Welcome to</h4>
							<h2 className="display-3 fw-bold title">The Bridge Code</h2>
							<p>The Bridge Code Technologies is a leading Software and Web Application development Company serving Clients across the globe. The Bridge Code Technologies commenced its
								operations in Ahmedabad, India. The aim was to develop a single stop solution hub for the entire information technology requirements of the modern organizations.</p>
							<p>Throughout our triumphant journey till date, we were always concentrated towards achieving excellence by implementing innovative technologies which enabled us to
								have a group of satisfied customers. Since its inception, the company maintained a steady growth amidst severe conditions and competition in the market.</p>
						</div>
					</div>
				</div>

				<section className="container-fluid block1 p-5 mt-5 mb-5">
					<div className="container d-flex">
						<div className="row text-center align-item-center text-white ">
							<div className="col-md-4">
								<img src="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/abouts/1sprite-1.png"  alt="Vision" className="img-fluid"/>
								<h1>Vision</h1>
								<p>Our vision is to be the best among the IT service providers across the globe.</p>
							</div>

							<div className="col-md-4">
								<img src="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/abouts/1sprite-2.png" alt="Mission" className="img-fluid"/>
								<h1>Mission</h1>
								<p>Our mission is to equip our customers with the most advanced and world class IT solutions which can enable them to have an edge over their competitors.</p>
							</div>

							<div className="col-md-4">
								<img src="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/abouts/1sprite-3.png" alt="Moto" className="img-fluid"/>
								<h1>Moto</h1>
								<p>Our moto is to create IT solutions for a better tomorrow.</p>
							</div>
						</div>
					</div>
				</section>

				<div className="container-fluid mb-5">
					<div className="container">
						<div className="row text-center justify-content-between">
							<h1 className="display-2 fw-bold title-c mb-5">Our Infrastructure</h1>
							<div className="col-12 col-md-6 col-lg-4 p-2 mt-3">
								<div className="h-100 rounded-3 shadow py-3 px-2 bg-body">
									<div className="mb-5"><FaBuilding size={'3.5rem'} /></div>
									<h4>1200+ sq. ft of office space</h4>
								</div>
							</div>
							<div className="col-12 col-md-6 col-lg-4 p-2 mt-3">
								<div className="h-100 rounded-3 shadow py-3 px-2 bg-body" >
									<div className="mb-5"><FaEnvelopeOpenText size={'3.5rem'} /></div>
									<h4>24 x 7 Availability</h4>
								</div>
							</div>
							<div className="col-12 col-md-6 col-lg-4 p-2 mt-3">
								<div className="h-100 rounded-3 shadow py-3 px-2 bg-body">
									<div className="mb-5"><FaDesktop size={'3.5rem'} /></div>
									<h4>Fully Equipped development Center</h4>
								</div>
							</div>
							<div className="col-12 col-md-6 col-lg-4 p-2 mt-3">
								<div className="h-100 rounded-3 shadow py-3 px-2 bg-body" >
									<div className="mb-5"><FaRegCalendarCheck size={'3.5rem'} /></div>
									<h4>Fingerprint Security</h4>
								</div>
							</div>
							<div className="col-12 col-md-6 col-lg-4 p-2 mt-3">
								<div className="h-100 rounded-3 shadow py-3 px-2 bg-body">
									<div className="mb-5"><FaSignal size={'3.5rem'} /></div>
									<h4>Internet leased lines</h4>
								</div>
							</div>
							<div className="col-12 col-md-6 col-lg-4 p-2 mt-3">
								<div className="h-100 rounded-3 shadow py-3 px-2 bg-body">
									<div className="mb-5"><FaLink size={'3.5rem'} /></div>
									<h4>Uninterrupted power supply</h4>
								</div>
							</div>
							<div className="col-12 col-md-6 col-lg-4 p-2 mt-3">
								<div className="h-100 rounded-3 shadow py-3 px-2 bg-body" >
									<div className="mb-5"><FaBookReader size={'3.5rem'} /></div>
									<h4>High Efficiency Development , Support, and Testing</h4>
								</div>
							</div>
							<div className="col-12 col-md-6 col-lg-4 p-2 mt-3">
								<div className="h-100 rounded-3 shadow py-3 px-2 bg-body">
									<div className="mb-5"><FaCloudflare size={'3.5rem'} /></div>
									<h4>Automated & Manual backup services</h4>
								</div>
							</div>
							<div className="col-12 col-md-6 col-lg-4 p-2 mt-3">
								<div className="h-100 rounded-3 shadow py-3 px-2 bg-body">
									<div className="mb-5"><FaUsers size={'3.5rem'} /></div>
									<h4>Friendly work Environment </h4>
								</div>
							</div>
						</div>
					</div>
				</div>

				<section className="container-fluid block2 p-0">
					<div className="container text-center p-5">
						<h1 className="text-white mt-2 display-4">Our Strength</h1>
						<p className="text-primary mt-1 fs-3">Our biggest strength lies in our dynamic skilled Workforce</p>
						<p className="text-white px-5 ">The Bridge Code Technologies LLP has got an exceptionally talented dedicated team of professionals with unique domain specific skills who perfectly understand the Client's Business and work relentlessly to meet the expectations. Our team’s genuine passion and efforts to deliver excellence in each and every project sets the company apart from our competitors.</p>
						<button className="cl-button -primary -small rounded-2 pt-2"><Link to='/contact-us' className="text-white text-decoration-none">Contact Us <BsFillArrowRightCircleFill /></Link></button>
					</div>
				</section>
			</div>
		</>
	);
};

export default About;