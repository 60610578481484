import React from 'react';
import { BsFillHandThumbsUpFill, BsFillArrowRightCircleFill, BsCart4, BsCodeSlash, BsStack, BsLayoutWtf, BsBraces, BsPhoneVibrate } from "react-icons/bs";
import Faq from "react-faq-component";
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";

const data = {
    title: <h1 className='pb-3 display-5 fw-bold title'>FAQ (How it works)</h1>,
    rows: [
        {
            title: <b>What is a dedicated team hiring model?</b>,
            content: `A dedicated team hiring model is a collaboration model where a client and developers’ team collaborate on a long-term basis. Hiring a dedicated team allows you to have a team of professionals that specifically work towards the clients’ business goals.`,
        },
        {
            title: <b>Why to hire offshore developers?</b>,
            content: `Offshore developers prove to be quite profitable in all aspects when looking to create your own remote team. It also proves beneficial when you have a big project in hand for which you would be needing domain expertise. You can leverage global talent pool to get fresh perspectives for your product development. Hiring offshore developers also reduces the operational cost and the product is brought to the market way quickly than the usual product launch time. Having a remote team also allows businesses to scale up or down as per the project needs.`,
        },
        {
            title: <b>When to Hire Dedicated Developers for Your Project?</b>,
            content: `It is best to opt for dedicated developers for the project when: 
            <ul>
                <li class="list-group-item">1) You are a fast growing company </li>
                <li class="list-group-item">2) You have a low budget for the project</li>
                <li class="list-group-item">3) There is a lack of domain expertise</li>
                <li class="list-group-item">4) You have projects with dynamic scope</li>
            </ul>`,
        },
        {
            title: <b>How will I track the progress of my project?</b>,
            content: `At The Bridge Code, we understand the importance of project visibility which is why our clients have full access to basecamp where they can easily monitor the progress and can share their inputs & feedback regarding the project as and when required. Constant communication is a big part of our collaboration and it is stringently taken into account by conveying and brainstorming the project ideas through collaborative tools like skype, gmeet, etc. Apart from that, we also ensure that the project analysis reports are shared across mails and a proper documentation is put in place for future reference. `,
        },
        {
            title: <b>Will I get a resource add on in the middle of the project?</b>,
            content: `Yes, working with The Bridge Code gives you the perk to scale the team anytime as per the project demands. Be it any number of resources, our recruitment process continues as per the modulation in the client’s demands. We have a huge pool of resources that could be on-boarded anytime depending on the expertise needed for the profile. Therefore, when a client requests for an urgent scale up, we pull out the best professionals from the pool to fulfill the adhoc demands. `,
        },
        {
            title: <b>Why should you hire Dedicated Developers from The Bridge Code?</b>,
            content: `When you hire dedicated developers from The Bridge Code you get the following benefits:
            <ul>
                <li class="list-group-item">1) Domain expertise</li>
                <li class="list-group-item">2) 24*7 tech support</li>
                <li class="list-group-item">3) Quick product delivery</li>
                <li class="list-group-item">4) 60% lower cost</li>
                <li class="list-group-item">5) Code ownership</li>
            </ul>`,
        },
    ],
};

const styles = {
    bgColor: 'white',
    titleTextColor: "#000",
    rowTitleColor: "#000",
    rowContentColor: '#000',
    arrowColor: "#000",
    transitionDuration: "1s",
};

const config = {
    animate: true,
    // arrowIcon: "V",
    tabFocus: true,
    expandIcon: "+",
    collapseIcon: "-",
};

// ================================|| HireDedicatedResource ||================================ //

const HireDedicatedResource = () => {
    return (
        <>
            <Helmet>
				<title>Services - The Bridge Code</title>
			</Helmet>
            <div className='container-fluid p-0'>
                <div class="position-relative overflow-hidden text-center hybrid">
                    <div class="col-md-7 p-lg-5 mx-auto my-1">
                        <h1 class="display-2 fw-bolder text-white title-c">Hire Dedicated Resources</h1>
                        <p class="lead fw-normal text-light">Build your Dream Team. It's Easy you select and we deploy.</p>
                        <Link to='/contact-us'><button className='btn-change2'>Contact Us</button></Link>
                    </div>
                </div>

                <div className="container mt-5 mb-5">
                    <div className="row col-md-12">
                        <div className="col-md-6">
                            <div className="title"><h1>Overview</h1></div>
                            <p>Extend the limits of your current tech team and get high-end solutions from our experts. The Bridge Code helps global startups, enterprises, and companies
                                worldwide to develop world-class software apps and deliver the best technology solutions. Our team of developers is always ready to deliver the end vision
                                as per your demands. Does your project need on-demand software developers to expand your team? Or do you want to HIRE a team of dedicated software
                                developers or Consultants?</p>
                            <ul className="">
                                <li className="list-group-item p-1 fs-5"><BsFillHandThumbsUpFill /> Flexibility</li>
                                <li className="list-group-item p-1 fs-5"><BsFillHandThumbsUpFill /> You can Focus on your Business</li>
                                <li className="list-group-item p-1 fs-5"><BsFillHandThumbsUpFill /> Cost-Efficiency</li>
                                <li className="list-group-item p-1 fs-5"><BsFillHandThumbsUpFill /> Access a Vast Talent Pool</li>
                                <li className="list-group-item p-1 fs-5"><BsFillHandThumbsUpFill /> Expertise</li>
                                <li className="list-group-item p-1 fs-5"><BsFillHandThumbsUpFill /> Global Outreach</li>
                                <li className="list-group-item p-1 fs-5"><BsFillHandThumbsUpFill /> More Control</li>
                                <li className="list-group-item p-1 fs-5"><BsFillHandThumbsUpFill /> Fluent Cooperation</li>
                            </ul>
                        </div>
                        <div className="col-md-6 align-self-center">
                            <img src="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/hire_deve/team_hire.gif" alt="" className="img-fluid" width={'100%'} />
                        </div>
                    </div>
                </div>

                <div className="container-fluid hire_back mb-5 pb-5">
                    <div className="container pb-5">
                        <div className="row mt-5 justify-content-between">
                            <h3 className='text-center'>HIRE DEDICATED DEVELOPERS</h3>
                            <h1 className="display-2 fw-bold title-c mb-5">India’s elite IT developers for hire</h1>

                            <div className="row justify-content-between">
                                <div className="col-lg-5 col-md-8 col-sm-12 bg-body rounded shadow-lg mx-2 my-2 py-4">
                                    <h3 className='text-center pb-2 title-c'>Hire by Role</h3>
                                    <ul className="">
                                        <li className="list-group-item pb-1 fs-5"><BsFillHandThumbsUpFill style={{ color: '#20B2AA' }} /> Hire Web Developer</li>
                                        <li className="list-group-item pb-1 fs-5"><BsFillHandThumbsUpFill style={{ color: '#20B2AA' }} /> Hire Backend Team Lead</li>
                                        <li className="list-group-item pb-1 fs-5"><BsFillHandThumbsUpFill style={{ color: '#20B2AA' }} /> Hire Full Stack Developer</li>
                                        <li className="list-group-item pb-1 fs-5"><BsFillHandThumbsUpFill style={{ color: '#20B2AA' }} /> Hire Software Developer</li>
                                        <li className="list-group-item pb-1 fs-5"><BsFillHandThumbsUpFill style={{ color: '#20B2AA' }} /> Hire Project Manager</li>
                                        <li className="list-group-item pb-1 fs-5"><BsFillHandThumbsUpFill style={{ color: '#20B2AA' }} /> Hire Software Team Lead</li>
                                        <li className="list-group-item pb-1 fs-5"><BsFillHandThumbsUpFill style={{ color: '#20B2AA' }} /> Hire Mobile App Developer</li>
                                        <li className="list-group-item pb-1 fs-5"><BsFillHandThumbsUpFill style={{ color: '#20B2AA' }} /> Hire QA Team Lead</li>
                                        <li className="list-group-item pb-1 fs-5"><BsFillHandThumbsUpFill style={{ color: '#20B2AA' }} /> Hire Frontend Developer</li>
                                        <li className="list-group-item pb-1 fs-5"><BsFillHandThumbsUpFill style={{ color: '#20B2AA' }} /> Hire UX Team Lead</li>
                                        <Link to='/contact-us'><button className='btn-change2 mt-4'>Contact Us <BsFillArrowRightCircleFill /></button></Link>
                                    </ul>
                                </div>
                                <div className="col-lg-5 col-md-8 col-sm-12 bg-body rounded shadow-lg mx-2 my-2 py-4">
                                    <h3 className='text-center pb-2 title-c'>Hire by Skills</h3>
                                    <ul className="">
                                        <li className="list-group-item pb-1 fs-5"><BsFillHandThumbsUpFill style={{ color: '#20B2AA' }} /> Asp.Net</li>
                                        <li className="list-group-item pb-1 fs-5"><BsFillHandThumbsUpFill style={{ color: '#20B2AA' }} /> React</li>
                                        <li className="list-group-item pb-1 fs-5"><BsFillHandThumbsUpFill style={{ color: '#20B2AA' }} /> JavaScript</li>
                                        <li className="list-group-item pb-1 fs-5"><BsFillHandThumbsUpFill style={{ color: '#20B2AA' }} /> Python</li>
                                        <li className="list-group-item pb-1 fs-5"><BsFillHandThumbsUpFill style={{ color: '#20B2AA' }} /> Flutter</li>
                                        <li className="list-group-item pb-1 fs-5"><BsFillHandThumbsUpFill style={{ color: '#20B2AA' }} /> Swift</li>
                                        <li className="list-group-item pb-1 fs-5"><BsFillHandThumbsUpFill style={{ color: '#20B2AA' }} /> C/C++/C#</li>
                                        <li className="list-group-item pb-1 fs-5"><BsFillHandThumbsUpFill style={{ color: '#20B2AA' }} /> Objective C</li>
                                        <li className="list-group-item pb-1 fs-5"><BsFillHandThumbsUpFill style={{ color: '#20B2AA' }} /> Java</li>
                                        <li className="list-group-item pb-1 fs-5"><BsFillHandThumbsUpFill style={{ color: '#20B2AA' }} /> Wordpress</li>
                                        <Link to='/contact-us'><button className='btn-change2 mt-4'>Contact Us <BsFillArrowRightCircleFill /></button></Link>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container p-5">
                    <div className="text-center mx-5">
                        <h1 className="title-c">Hire dedicated software developers FOR YOUR NEW PROJECTS</h1>
                        {/* <p>ScienceSoft welcomes possibilities to assist in specific development stages as well as to provide full-cycle mobile app implementation. Our mobile app development services include:</p> */}
                    </div>
                    <div className="row text-center justify-content-between">
                        <div className="col-md-3 bg-body rounded shadow-lg mx-2 my-4">
                            <p className=''><BsCodeSlash style={{ color: '#20B2AA' }} size="56" /></p>
                            <h4 className='pt-2'>Hire Software Developers</h4>
                            <p>Our software developers have vast experience in developing various software applications including CMS, mobile apps, and Ecommerce stores.</p>
                        </div>
                        <div className="col-md-3 bg-body rounded shadow-lg mx-2 my-4">
                            <p className='pt-3'><BsLayoutWtf style={{ color: '#20B2AA' }} size="56" /></p>
                            <h4 className='pt-2'>Hire Frontend Developers</h4>
                            <p>A proficient Team of Frontend developers who can develop interactive and secure frontend web interfaces with high-end design skills and Talent.</p>
                        </div>
                        <div className="col-md-3 bg-body rounded shadow-lg mx-2 my-4">
                            <p className=''><BsStack style={{ color: '#20B2AA' }} size="56" /></p>
                            <h4 className='pt-2'>Hire Full-stack Developers</h4>
                            <p>Leverage a pool of skilled Full stack developers for your projects for building custom web solutions for startups, SMEs, and enterprises</p>
                        </div>
                        <div className="col-md-3 bg-body rounded shadow-lg mx-2 my-4">
                            <p className=''><BsBraces style={{ color: '#20B2AA' }} size="56" /></p>
                            <h4 className='pt-2'>Hire Backend Developers</h4>
                            <p>The Bridge Code certified developers can create scalable, secure, and performance-oriented real-time web apps that help your company gain a competitive advantage.</p>
                        </div>
                        <div className="col-md-3 bg-body rounded shadow-lg mx-2 my-4">
                            <p className=''><BsCart4 style={{ color: '#20B2AA' }} size="56" /></p>
                            <h4 className='pt-2'>Hire Ecommerce Developers</h4>
                            <p>With the help of Ecommerce development expertise, our team develops Ecommerce web applications and makes them customized as per your requirements.</p>
                        </div>
                        <div className="col-md-3 bg-body rounded shadow-lg mx-2 my-4">
                            <p className=''><BsPhoneVibrate style={{ color: '#20B2AA' }} size="56" /></p>
                            <h4 className='pt-2'>Hire Mobile App Developers</h4>
                            <p>You can work with multiple mobile app development technologies (cross-platform/native) and industry best practices by hiring app developers from The Bridge Code.</p>
                        </div>
                    </div>
                </div>

                {/* <!-- Cta--> */}
                <div className="container-fluid p-0 m-0">
                    <div className="parallax-container py-5">
                        <div className="parallax-content section-xl">
                            <div className="container">
                                <div className="row justify-content-end text-right">
                                    <div className="col-sm-7 text-center">
                                        <h3 className="display-4 fw-bold text-white mb-5">Why The Bridge Code?</h3>
                                        <p className='text-light mb-4'>The Bridge Code Technologies is a Firm where we provide software development, integrations, and resources you need to compete
                                            in the Fast changing world. We believe in the power of marketing, sales, content management and great customer service are key to success in any
                                            Business. We have started a New Approach for our Clients where they can see the Potential of our Skills and Software. Try our Free Demo! </p>
                                        <p className='mt-3 text-white fs-4 fw-bold'>If you have the Passion, We have the Plan!!</p>
                                        <div className="mt-4">
                                            <Link to='/solution' className="cl-button -primary -small mx-1">Try Free Demo</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container pt-5 pb-5">
                    <div className="col-md-12 ">
                        <div className="col-md-8 mx-auto">
                            <Faq data={data} styles={styles} config={config} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default HireDedicatedResource;