import React, { useState } from 'react';
import API from '../../../middleware/API';
import { useCookies } from "react-cookie";
import { Helmet } from "react-helmet";
import { Link, useNavigate } from 'react-router-dom';
import { FaEye, FaEyeSlash } from "react-icons/fa";

// ================================|| Login ||================================ //

const Login = () => {
  const [formStatus, setFormStatus] = React.useState('Send');
  const [cookies, setCookie] = useCookies(['username']);
  const [id, setId] = useCookies(['id']);
  const [recordset, setrecordsets] = useState();
  const [isVisible, setVisible] = useState(false);
  const navigate = useNavigate();

  const toggle = () => {
    setVisible(!isVisible);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setFormStatus('Submitting...');
    const { username, password } = e.target.elements;
    try {
      var LoginAPI = API.userLogin;
      LoginAPI = LoginAPI + "?username=" + username.value + "&password=" + password.value;
      const response = await fetch(LoginAPI);
      const jsonData = await response.json();
      setrecordsets(jsonData.recordset);
      var status = jsonData.recordset[0].Status;
      var user = jsonData.recordset[0].FullName;
      var image = jsonData.recordset[0].Image;
      var userid = jsonData.recordset[0].Id;
      var roleid = jsonData.recordset[0].Role_Id;
      var role = jsonData.recordset[0].RoleName;

      if (status == 1) {
        setCookie('username', user, { path: '/admin/' });
        setCookie('image', image, { path: '/admin/' });
        setCookie('id', userid, { path: '/admin/' });                         // Set the id in cookies 
        setCookie('roleid', roleid, { path: '/admin/' });                     // Set the id in cookies 
        setCookie('role', role, { path: '/admin/' });
        window.open("/admin/dashboard", "_blank");
        navigate("/");
      }
      else {
        alert("Please check your credentials & try again.");
      }
    }
    catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Helmet>
        <title>Login - The Bridge Code</title>
      </Helmet>

      <div class="container-xxl">
        <div class="authentication-wrapper authentication-basic container-p-y">
          <div class="authentication-inner">
            {/* <!-- Register --> */}
            <div class="card p-2">
              <div class="card-body">
                {/* <!-- Logo --> */}
                <div class="app-brand justify-content-center">
                  <Link to='/' class="app-brand-link gap-2">
                    <Link to='/'><img src="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/TBC-logo.png" className="img-fluid" alt="The Bridge Code" /></Link>
                  </Link>
                </div>
                {/* <!-- /Logo --> */}
                <h4 class="mb-2">Welcome to Bridge Code! 👋</h4>
                <p class="mb-4">Please sign-in to your account and start the adventure</p>

                <form id="formAuthentication" class="mb-3" onSubmit={onSubmit}>
                  <div class="mb-3">
                    <label for="email" class="form-label">Email or Username</label>
                    <input type="text" class="form-control" id="email" name="username" placeholder="Enter your email or username" autofocus />
                  </div>
                  <div class="mb-3 form-password-toggle">
                    <div class="d-flex justify-content-between">
                      <label class="form-label" for="password">Password</label>
                    </div>
                    <div class="input-group input-group-merge">
                      <input type={!isVisible ? "password" : "text"} id="password" class="form-control" name="password" placeholder="Enter your password" aria-describedby="password" />
                      <span className="input-group-text" onClick={toggle}>
                        {isVisible ? <FaEye /> : <FaEyeSlash />}
                      </span>
                    </div>
                  </div>
                  <div class="mb-3">
                    <button class="btn btn-primary d-grid w-100" type="submit">Sign in</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;