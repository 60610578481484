import React from 'react';
import { BsFillHandThumbsUpFill, BsFillCheckCircleFill } from "react-icons/bs";
import Faq from "react-faq-component";
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";

const data = {
    title: <h1 className='pb-3 display-5 fw-bold title'>FAQ (How it works)</h1>,
    rows: [
        {
            title: <b>1. What is custom software development?</b>,
            content: `Custom software development is the process of designing a software that is specially developed for a group of users within an organization. It is built to address specific user's requirements and is viewed as more effective than commercial or off-the-shelf softwares.`,
        },
        {
            title: <b>2. How do I decide between custom software or “off the shelf” software?</b>,
            content: `There are several key considerations in making this decision, but two primary reasons: feature set and cost.  If the feature set of an off the shelf piece of software matches your business requirement and also meets your budget needs, it’s an easy decision from that point.<br />
            But Cheaper alternatives like off-the-shelf software development have their downsides one being they are made for mass marketing and not a specific purpose unlike custom software.`,
        },
        {
            title: <b>3. What is the Development cost?</b>,
            content: `The cost Factor will depend on a lot of things like :<br />
            a. Technology you choose<br />
            b. The Size of the software<br />
            c. The complexity of the design and software itself<br />
            d. Does it need any 3rd party API integrations and integration with other systems<br />
            e. How many developers will be working on the project<br />
            f. Time Complexity<br />
            There will be recurring Monthly Expenses of Servers and certificates which also needs to be kept in mind.`,
        },
        {
            title: <b>4. What are the advantages and disadvantages of custom software development?</b>,
            content: `Advantages :<br />
            a. Solutions are tailor-made<br />
            b. Guaranteed ROI<br />
            c. Safe and Secure<br />
            d. Adaptability<br />
            e. Easy to Use<br />
            f. Support System<br />
            g. Long-term effects<br /><br />
            Disadvantages :<br />
            a. Little Expensive<br />
            b. Investment of time and effort`,
        },
        {
            title: <b>5. Are Custom Software worth the money?</b>,
            content: `Yes, they are. They may be a big investment but the long term results will definitely be beneficial to your business as a whole.`,
        },
        {
            title: <b>6. What happens after development?</b>,
            content: `We at The Bridge Code offer services like configuration, customization as well as backup services and of course, Maintenance. Offcourse this is Optional whether you wish to continue the Maintenance work with us. We will deliver all the Source code and Ownership to you once the Project is Live and Running.`,
        },
    ],
};

const styles = {
    bgColor: 'white',
    titleTextColor: "#000",
    rowTitleColor: "#000",
    rowContentColor: '#000',
    arrowColor: "#000",
    transitionDuration: "1s",
};

const config = {
    animate: true,
    tabFocus: true,
    expandIcon: "+",
    collapseIcon: "-",
};

// ================================|| CustomSoftwareDevelopment ||================================ //

const CustomSoftwareDevelopment = () => {
    return (
        <>
            <Helmet>
				<title>Custom Software Development - The Bridge Code</title>
			</Helmet>
            <div className='container-fluid p-0'>
                <div class="position-relative overflow-hidden text-center csd">
                    <div class="col-md-7 p-lg-5 mx-auto my-1">
                        <h1 class="display-2 fw-bolder text-white title-c">Custom Software Development</h1>
                        <p class="lead fw-normal text-light">We Brainstorm and Develop according to your Business Needs.</p>
                        <Link to='/contact-us'><button className='btn-change2'>Contact Us</button></Link>
                    </div>
                </div>

                <div className="container mt-5 mb-5">
                    <div className="row col-md-12">
                        <div className="col-md-6">
                            <div className="title"><h1>Overview</h1></div>
                            <p className="display-5">Build next gen Custom Software Development</p>
                            <p>Our intelligent Custom web development simplifies your Business needs and it controls with a powerful back-end support, which is Developed with a
                                modern approach the custom web applications automate the business process, manage data and make your work easier with solutions. We deliver
                                websites with the performance you need for the results you want.</p>
                            <ul className="">
                                <li className="list-group-item p-1 fs-5"><BsFillHandThumbsUpFill /> Unique Solution that Belongs to Your Business Only</li>
                                <li className="list-group-item p-1 fs-5"><BsFillHandThumbsUpFill /> Personalized Solution</li>
                                <li className="list-group-item p-1 fs-5"><BsFillHandThumbsUpFill /> Customer Satisfaction</li>
                                <li className="list-group-item p-1 fs-5"><BsFillHandThumbsUpFill /> Security</li>
                                <li className="list-group-item p-1 fs-5"><BsFillHandThumbsUpFill /> Integration</li>
                                <li className="list-group-item p-1 fs-5"><BsFillHandThumbsUpFill /> Flexibility & Scalability</li>
                                <li className="list-group-item p-1 fs-5"><BsFillHandThumbsUpFill /> Reliability</li>
                                <li className="list-group-item p-1 fs-5"><BsFillHandThumbsUpFill /> Efficiency</li>
                                <li className="list-group-item p-1 fs-5"><BsFillHandThumbsUpFill /> Scalability</li>
                            </ul>
                        </div>
                        <div className="col-md-6 align-self-center">
                            <img src="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/csd/Cust_Software_Dev.svg" alt="" className="img-fluid" />
                        </div>
                    </div>
                </div>

                <div className="container-fluid csd_serv">
                    <div className="container">
                        <div className="row text-center justify-content-between">
                            <h1 className="display-2 fw-bold text-light title-c mb-5">Technologies We Use</h1>

                            <div className="three rounded shadow-lg text-white pt-3 mt-5" style={{ backgroundColor: "rgb(0,0,0,0.3)" }}>
                                <div className="mb-3"><img src="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/csd/ASP_Net.png" className="img-fluid bg-body" width={125} alt="ASP-Net" /></div>
                                <h4>ASP.Net</h4>
                                <p className="text-light">Free. Cross-platform. Open source. A framework for building web apps and services with .NET. Interactive web UI with C#
                                    Blazor is a feature of ASP.NET for building interactive web UIs using C# instead of JavaScript. Blazor gives you real .NET running in the
                                    browser on WebAssembly. .NET is a developer platform made up of tools, programming languages, and libraries for building many different types of applications.</p>
                                <div className="row px-2">
                                    <h4 className="text-start title">Features</h4>
                                    <ul className="col-md-5 text-start">
                                        <li className="list-group-item p-1"><BsFillCheckCircleFill /> High flexibility</li>
                                        <li className="list-group-item p-1"><BsFillCheckCircleFill /> Open-source</li>
                                        <li className="list-group-item p-1"><BsFillCheckCircleFill /> Fast and scalable</li>
                                    </ul>
                                    <ul className="col-md-6 text-start">
                                        <li className="list-group-item p-1"><BsFillCheckCircleFill /> Build secure apps</li>
                                        <li className="list-group-item p-1"><BsFillCheckCircleFill /> Free hosting on Azure</li>
                                    </ul>
                                </div>
                            </div>

                            <div className="three rounded shadow-lg text-white pt-3 mt-5">
                                <div className="mb-3"><img src="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/csd/NET_Core.png" className="img-fluid bg-body" width={96} alt=".net-core" /></div>
                                <h4>ASP.Net Core</h4>
                                <p className="text-light">Free. Cross-platform. Open source. A framework for building web apps and services with .NET. Interactive web UI with C#
                                    Blazor is a feature of ASP.NET for building interactive web UIs using C# instead of JavaScript. Blazor gives you real .NET running in the
                                    browser on WebAssembly. .NET is a developer platform made up of tools, programming languages, and libraries for building many different types of applications.</p>
                                <div className="row px-2">
                                    <h4 className="text-start title">Features</h4>
                                    <ul className="col-md-5 text-start">
                                        <li className="list-group-item p-1"><BsFillCheckCircleFill /> High flexibility</li>
                                        <li className="list-group-item p-1"><BsFillCheckCircleFill /> Open-source</li>
                                        <li className="list-group-item p-1"><BsFillCheckCircleFill /> Fast and scalable</li>
                                    </ul>
                                    <ul className="col-md-6 text-start">
                                        <li className="list-group-item p-1"><BsFillCheckCircleFill /> Build secure apps</li>
                                        <li className="list-group-item p-1"><BsFillCheckCircleFill /> Free hosting on Azure</li>
                                    </ul>
                                </div>
                            </div>

                            <div className="three rounded shadow-lg text-white pt-3 mt-5" style={{ backgroundColor: "rgb(0,0,0,0.3)" }}>
                                <div className="mb-3"><img src="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/csd/react.png" className="img-fluid bg-body" alt="flutter" /></div>
                                <h4>React App Development</h4>
                                <p className="text-light">We develop react mobile apps that are highly interactive, scalable, and equipped with dynamic features. With our
                                    skilled and qualified designers at your disposal, you can expect React apps that match your business standards easily. Such applications can
                                    create better business reach and user satisfaction as compared to others.</p>
                                <div className="row px-2">
                                    <h4 className="text-start title">Features</h4>
                                    <ul className="col-md-5 text-start">
                                        <li className="list-group-item p-1"><BsFillCheckCircleFill /> CSS</li>
                                        <li className="list-group-item p-1"><BsFillCheckCircleFill /> HTML</li>
                                        <li className="list-group-item p-1"><BsFillCheckCircleFill /> Ruby On Rails (API)</li>
                                    </ul>
                                    <ul className="col-md-6 text-start">
                                        <li className="list-group-item p-1"><BsFillCheckCircleFill /> JavaScript</li>
                                        <li className="list-group-item p-1"><BsFillCheckCircleFill /> Node.Js</li>
                                        <li className="list-group-item p-1"><BsFillCheckCircleFill /> ReactJS</li>
                                    </ul>
                                </div>
                            </div>

                            <div className="three rounded shadow-lg text-white pt-3 mt-5" >
                                <div className="mb-3"><img src="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/csd/python.png" className="img-fluid bg-body" alt="python" /></div>
                                <h4>Python</h4>
                                <p className="text-light">Python is a great language to go for web development if you want to do just about anything. It’s an incredibly versatile language that you can
                                    employ in any number of industries and for any number of purposes. Plus, it’s easy to learn if you already have some experience with other programming languages, and top
                                    IT companies are looking for people who know Python.</p>
                                <div className="row px-2">
                                    <h4 className="text-start title">Features</h4>
                                    <ul className="col-md-5 text-start">
                                        <li className="list-group-item p-1"><BsFillCheckCircleFill /> Free to use</li>
                                        <li className="list-group-item p-1"><BsFillCheckCircleFill /> Supports tons of libraries</li>
                                        <li className="list-group-item p-1"><BsFillCheckCircleFill /> Enhanced Productivity</li>
                                    </ul>
                                    <ul className="col-md-6 text-start">
                                        <li className="list-group-item p-1"><BsFillCheckCircleFill /> High code readability</li>
                                        <li className="list-group-item p-1"><BsFillCheckCircleFill /> Offers portability</li>
                                    </ul>
                                </div>
                            </div>

                            <div className="three rounded shadow-lg text-white pt-3 mt-5" style={{ backgroundColor: "rgb(0,0,0,0.3)" }}>
                                <div className="mb-3"><img src="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/csd/azure.png" className="img-fluid bg-body" alt="azure" /></div>
                                <h4>Azure Container Service</h4>
                                <p className="text-light">Over the last few years, people have increasingly been adopting containers. But to use containers at a large scale,
                                    you need to use an orchestrator to ease the administration of your applications. Kubernetes is the most popular orchestrator and, while there
                                    are many concepts you need to learn to make the most of benefits.</p>
                                <div className="row px-2">
                                    <h4 className="text-start title">Features</h4>
                                    <ul className="col-md-5 text-start">
                                        <li className="list-group-item p-1"><BsFillCheckCircleFill /> Highly scalable language</li>
                                        <li className="list-group-item p-1"><BsFillCheckCircleFill /> Facilitates quick</li>
                                        <li className="list-group-item p-1"><BsFillCheckCircleFill /> Multiple built-in</li>
                                    </ul>
                                    <ul className="col-md-6 text-start">
                                        <li className="list-group-item p-1"><BsFillCheckCircleFill /> High Portability</li>
                                        <li className="list-group-item p-1"><BsFillCheckCircleFill /> Vast community</li>
                                    </ul>
                                </div>
                            </div>

                            <div className='three'></div>
                        </div>
                    </div>
                </div>

                <div className="container p-5">
                    <div className="text-center mx-5">
                        <h1 className="title-c">Our Custom Software Development Services</h1>
                        <p>Prototypical single-page applications, cloud-based designs, web content management, friction-free ecommerce platforms — we build every type of web-based solution imaginable, all with robust front- and backends.</p>
                    </div>
                    <div className="row text-center justify-content-between">
                        <div className="col-md-3 bg-body rounded shadow-lg mx-2 my-4">
                            <img src="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/csd/01.png" alt="Custom Software Development Services" width={100} className="img-fluid p-2" />
                            <h4>Custom Software Development Services</h4>
                            <p>We offer end-to-end custom software development for businesses to maximize their proficiency. Bridge Code as a software development company ensures delivery of bespoke software solutions that are meant for your target audience and their specific needs.</p>
                        </div>
                        <div className="col-md-3 bg-body rounded shadow-lg mx-2 my-4">
                            <img src="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/csd/02.png" alt="Enterprise Software Development Services" width={100} className="img-fluid p-2" />
                            <h4>Enterprise Software Development Services</h4>
                            <p>Develop next-gen enterprise level software solutions with us to scale, automate and optimize your business processes. We effectively digitize your business with smooth API integration and latest legacy systems to ensure creation of robust and secure custom software solutions.</p>
                        </div>
                        <div className="col-md-3 bg-body rounded shadow-lg mx-2 my-4">
                            <img src="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/csd/03.png" alt="Software Product Development Services" width={100} className="img-fluid p-2" />
                            <h4>Software Product Development Services</h4>
                            <p>The Bridge Code is among the top software product development companies that offers comprehensive product development services to produce stable, engaging, cost-friendly as well as high quality software products.</p>
                        </div>
                        <div className="col-md-3 bg-body rounded shadow-lg mx-2 my-4">
                            <img src="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/csd/04.png" alt="Software Consulting Services" width={100} className="img-fluid p-2" />
                            <h4>Software Consulting Services</h4>
                            <p>We offer a team of experts that assist in developing custom software solutions that are cutting-edge and market ready. Our software consultants help you finalize the most user friendly custom software design that accelerate your growth and empower your business multifold.</p>
                        </div>
                        <div className="col-md-3 bg-body rounded shadow-lg mx-2 my-4">
                            <img src="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/csd/05.png" alt="Software Integration Services" width={100} className="img-fluid p-2" />
                            <h4>Software Integration Services</h4>
                            <p>We help integrate the best software technology into your business with the help of our expert custom software developers. Our custom software services ensure correct implementation of techniques and overcoming integration challenges.</p>
                        </div>
                        <div className="col-md-3 bg-body rounded shadow-lg mx-2 my-4">
                            <img src="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/csd/06.png" alt="API Development Services" width={100} className="img-fluid p-2" />
                            <h4>API Development Services</h4>
                            <p>The Bridge Code is amongst the top application development companies with excellent API integration services to offer. You can connect with your customers or vendors hasslefree using the customized development solutions.</p>
                        </div>
                        <div className="col-md-3 bg-body rounded shadow-lg mx-2 my-4">
                            <img src="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/csd/07.png" alt="Cloud Application Development Services" width={100} className="img-fluid p-2" />
                            <h4>Cloud Application Development Services</h4>
                            <p>Create scalable and secure cloud applications with our software services. Our custom software developers will ensure smooth deployment of applications and websites on cloud. We have a range of cloud computing strategies that help you build a scalable and flexible system.</p>
                        </div>
                        <div className="col-md-3 bg-body rounded shadow-lg mx-2 my-4">
                            <img src="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/csd/08.png" alt="Legacy Software Modernization" width={100} className="img-fluid p-2" />
                            <h4>Legacy Software Modernization</h4>
                            <p>Revitalize your legacy solution with our expert assistance. Our custom software development team helps in improving the technical architecture, migrating to the latest platforms, enhancing the user experience, making it device-agnostic, and ensuring compliance with current security standards.</p>
                        </div>
                        <div className="col-md-3 bg-body rounded shadow-lg mx-2 my-4">
                            <img src="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/csd/09.png" alt="Software Testing Services" width={100} className="img-fluid p-2" />
                            <h4>Software Testing Services</h4>
                            <p>Our target-driven software testers enable delivery of high quality custom software that meets the expected standards and functionality. We, as a custom software development company, ensure that our testing process is the most sought after to guarantee that a robust product is delivered to our clients.</p>
                        </div>
                    </div>
                </div>

                {/* <!-- Cta--> */}
                <div className="container-fluid p-0 m-0">
                    <div className="parallax-container py-5">
                        <div className="parallax-content section-xl">
                            <div className="container">
                                <div className="row justify-content-end text-right">
                                    <div className="col-sm-7 text-center">
                                        <h3 className="display-4 fw-bold text-white mb-5">Why The Bridge Code?</h3>
                                        <p className='text-light mb-4'>The Bridge Code Technologies is a Firm where we provide software development, integrations, and resources you need to compete
                                            in the Fast changing world. We believe in the power of marketing, sales, content management and great customer service are key to success in any
                                            Business. We have started a New Approach for our Clients where they can see the Potential of our Skills and Software. Try our Free Demo! </p>
                                        <p className='mt-3 text-white fs-4 fw-bold'>If you have the Passion, We have the Plan!!</p>
                                        <div className="mt-4">
                                            <Link to='/solution' className="cl-button -primary -small mx-1">Try Free Demo</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container pt-5 pb-5">
                    <div className="col-md-12 ">
                        <div className="col-md-8 mx-auto">
                            <Faq data={data} styles={styles} config={config} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CustomSoftwareDevelopment;