import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

// ================================|| Travel ||================================ //

const Travel = () => {
	return (
		<>		
			<Helmet>
				<title>Travel & Hospitality - The Bridge Code</title>
			</Helmet>
			<div className='container-fluid p-0 '>
				<div class="position-relative overflow-hidden text-center supplychain">
					<div class="col-md-5 p-lg-5 mx-auto my-1">
						<h1 class="display-2 fw-bolder text-white title-c">Travel & Hospitality Case Study</h1>
						<Link to='/contact-us' class="cl-button -primary -big rounded-3">Contact Us</Link>
					</div>
				</div>

				<div className="container">
					<div>
						<p style={{ marginTop: "50px" }}></p>
						<p>Some of the key influencers on travel and tourism in recent years have been globalization, digitalization, sustainability, and the coronavirus (COVID-19)
							pandemic. Ease of mobility, increased awareness of new destinations, and the internet as a source of information and commerce have caused this market to grow
							exponentially.
						</p>
						<p>SOS Travel Co. Ltd needed better data so they could make better decisions with their marketing spend. This meant finding an enterprise solution that helped
							them ensure accurate tracking from the very beginning of each campaign.</p>
						<p>SOS Travel Co. Ltd executed over 3000+ digital campaigns globally spanning paid search, display, affiliate, video, offline, social, and others on an annual
							basis. This required coordination across various parts of the organization.</p>
					</div>

					<div>
						<h4 className="text-h4">Challenge</h4>
						<p>The analytics team was completely responsible for creating and validating campaign tracking for the entire organization, including both marketing and internal campaigns.
							This process resulted in media execution delays and comprised a significant burden on the internal team, who had little time remaining to actually provide analytics support.
						</p>
						<p>Furthermore, the existing manual process, based on excel spreadsheet, could not account for every scenario, resulting in inconsistent data and human error. As
							an end result, analytics teams lacked the full campaign views they needed to make decisions.</p>
					</div>

					<div>
						<h4 className="text-h4">Solution</h4>
						<p>The Bridge Code helped SOS Travel Co. Ltd address these challenges by automating each stage of data governance by developing different marketing and automation software. The
							centralized platform enforced compliant tracking, validated pages and tags, and ensured consistent data standards across the organization. In addition, controls over data formats
							and flow, including automatic data import and export across platforms, allowed channel marketing teams to focus on execution over manual data management.
						</p>
					</div>

					<div>
						<h4 className="text-h4">How it was done</h4>
						<p>The Bridge Code enabled automated campaign tracking, allowing coverage to increase from 50% to 98% and vastly reduced the amount of time required for the analytics team. As a result,
							the company was able to obtain campaign insights, restoring trust in the data and instantly improving the ability of marketing leadership to make better decisions and adjust
							tactics to drive campaign success. In addition, The Bridge Code provided the foundation for attribution models and analytics tools needed to optimize marketing spend across the
							global organization. During the first 12 months post-implementation, revenue per available room (RevPAR) increased by nearly 2.5% YoY, while marketing spend decreased.
						</p>
						<p>After the first 6 months, the growth strategy had been systematically put in place. It featured major components such as below :
						</p>
					</div>

					<section className="steps steps-area">
						<div className="container">
							<ul className="timelines">
								<li className="timelines-box wow fadeInLeft">
									<i className="icon-layers"></i>
									<p className="timelines-title">1) Technology transformation :</p>
									<p className="timelines-details">
										The company worked with The Bridge Code to build automated management software, Chatbot and Dashboards to create a customer-centric experience.</p>
									<ul >
										<li className="py-1">
											<span className="timeline-point">a)</span> Chatbot installed into website Live Chat.
										</li>
										<li className="py-1">
											<span className="timeline-point">b)</span> Algorithms help manage hotel bookings, customer queries, and increase sales productivity.
										</li>
										<li className="py-1">
											<span className="timeline-point">c)</span> The customer-facing dashboard is fully automated where they can carry out single campaign into
											multiple social media accounts.
										</li>
									</ul>
									<span className="deee">1</span>
								</li>
								<li className="timelines-box wow fadeInRight">
									<i className="icon-chat"></i>
									<p className="timelines-title">2) Organizational Re-design : </p>
									<p className="timelines-details">Post-Covid the Company needed to stay ahead of their competitors, So we developed multi-level software where sales
										and marketing team can work together and conducted software Training with Staff members on a Weekly basis.</p>
									<span className="deee">2</span>
								</li>
								<li className="timelines-box  wow fadeInLeft">
									<i className="icon-tools"></i>
									<p className="timelines-title">3) Organizational Transformation : </p>
									<p className="timelines-details">Using the developed software, SOS Travel Co. Ltd created Campaign which saw Significant boost in sales and increased overall productivity of the Analytics and Internal Team.</p>
									<span className="deee">3</span>
								</li>
								<li className="timelines-box wow fadeInRight">
									<i className="icon-layers"></i>
									<p className="timelines-title">4) Digital Enablement :</p>
									<p className="timelines-details">Digital and Analytics tools were put in place to optimize key processes which increased website Traffic and saw a huge spike in conversion rate from 10% to 40%.</p>
									<span className="deee">4</span>
								</li>
								<li className="timelines-box wow fadeInLeft">
									<i className="icon-chat"></i>
									<p className="timelines-title">5) Talent Growth :</p>
									<p className="timelines-details">The Organization was strengthened with 50+ new hires in 12 months.</p>
									<span className="deee">5</span>
								</li>
							</ul>
						</div>
					</section>

					<div>
						<h4 className="text-h4">The Impact:</h4>
						<p>We build a single system that helps manage all of SOS Travel Co. Ltd distribution channels, providing them with automatic updates on all OTAs, GDS, metasearch
							engines, Rentals services and social media their hotel is connected to instantly. Hotel’s rate is easily updated throughout all channels, smooth real-time
							updates and predictive analytics based on users’ preferences and historical data help you avoid overbooking. An intelligent engine helps you reach a
							competitive edge by adjusting your pricing to the calendar events’ and your own market situation. HCM can be integrated with invoicing accounting software or
							separate features that are vital for the hospitality industry.</p>
						<p>The Software can be used to Analyze customer behaviors' and preferences, allowing for more targeted and effective marketing campaigns.</p>
						<p style={{ paddingBottom: "50px" }}></p>
					</div>

					<div class="container-cs" id="case_study">
						<div class="circles-container">
							<div class="circles circleOne"></div>
							<div class="circles circleTwo"></div>
							<div class="circles circleThree"></div>
							<div class="circles circleFour"></div>
							<div class="logo">Travel & Hospitality Case Study</div>
						</div>
						<svg width="1230" height="820" viewBox="0 0 1230 820" fill="none" xmlns="http://www.w3.org/2000/svg" class="mx-auto">
							<g opacity="0.15" class="line bottom bottomTwo">
								<path d="M615.5 661L615.5 497" stroke="#2378D5" stroke-width="12" />
							</g>
							<g opacity="0.15" class="line top topFour">
								<path d="M829 105L829 160.231C829 171.277 820.046 180.231 809 180.231L635 180.231C623.954 180.231 615 189.185 615 200.231L615 268" stroke="#2378D5" stroke-width="12" />
							</g>
							<g opacity="0.15" class="line top topThree">
								<path d="M615 104L615 268" stroke="#2378D5" stroke-width="12" />
							</g>
							<g opacity="0.15" class="line top topTwo">
								<path d="M402 106L402 160.769C402 171.815 410.954 180.769 422 180.769L595 180.769C606.046 180.769 615 189.724 615 200.769L615 268" stroke="#2378D5" stroke-width="12" />
							</g>
							<g opacity="0.15" class="line top topFive">
								<path d="M1041 105L1041 160.231C1041 171.276 1032.05 180.231 1021 180.231L635 180.231C623.954 180.231 615 189.185 615 200.231L615 268" stroke="#2378D5" stroke-width="12" />
							</g>
							<g opacity="0.15" class="line top topOne">
								<path d="M190 106L190 160.769C190 171.815 198.954 180.769 210 180.769L594.503 180.769C605.549 180.769 614.503 189.724 614.503 200.769L614.503 268" stroke="#2378D5" stroke-width="12" />
							</g>
							<g class="dots top dotsOne">
								<path d="M190 106L190 160.769C190 171.815 198.954 180.769 210 180.769L594.503 180.769C605.549 180.769 614.503 189.724 614.503 200.769L614.503 268" stroke="#2378D5" stroke-width="4" />
							</g>
							<g class="dots top dotsFive">
								<path d="M1041 105L1041 160.231C1041 171.276 1032.05 180.231 1021 180.231L635 180.231C623.954 180.231 615 189.185 615 200.231L615 268" stroke="#2378D5" stroke-width="4" />
							</g>
							<g class="dots top dotsTwo">
								<path d="M402 106L402 160.769C402 171.815 410.954 180.769 422 180.769L595 180.769C606.046 180.769 615 189.724 615 200.769L615 268" stroke="#2378D5" stroke-width="4" />
							</g>
							<g class="dots top dotsThree">
								<path d="M615 104L615 268" stroke="#2378D5" stroke-width="4" />
							</g>
							<g class="dots top dotsFour">
								<path d="M829 105L829 160.231C829 171.277 820.046 180.231 809 180.231L635 180.231C623.954 180.231 615 189.185 615 200.231L615 268" stroke="#2378D5" stroke-width="4" />
							</g>
							<g class="dots bottom dotsOne">
								<path d="M353.5 659.5L353.5 604.5C353.5 593.455 362.454 584.5 373.5 584.5L595 584.5C606.046 584.5 615 575.546 615 564.5L615 497" stroke="#2378D5" stroke-width="4" />
							</g>
							<g class="dots bottom dotsTwo">
								<path d="M615.5 661L615.5 497" stroke="#2378D5" stroke-width="4" />
							</g>
							<g class="dots bottom dotsThree">
								<path d="M877 659.5L877 604.5C877 593.454 868.046 584.5 857 584.5L635.5 584.5C624.454 584.5 615.5 575.545 615.5 564.5L615.5 497" stroke="#2378D5" stroke-width="4" />
							</g>
							<g filter="url(#filter0_d_484_1188)" class="block top topOne">
								<rect x="127" y="1" width="125" height="125" rx="6" fill="white" />
								<image x="141" y="23" width={105} xlinkHref="https://res.cloudinary.com/dm1muiikf/image/upload/v1690872419/website/case_study/aws.png" />
								<rect x="126.5" y="0.5" width="126" height="126" rx="6.5" stroke="#ADADAD" stroke-opacity="0.6" />
							</g>
							<g filter="url(#filter1_d_484_1188)" class="block top topTwo">
								<rect x="339.25" y="1" width="125" height="125" rx="6" fill="white" />
								<image x="343" y="1" width={115} xlinkHref="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/csd/sql.png" />
								<rect x="338.75" y="0.5" width="126" height="126" rx="6.5" stroke="#ADADAD" stroke-opacity="0.6" />
							</g>
							<g filter="url(#filter2_d_484_1188)" class="block top topThree">
								<rect x="551.5" y="1" width="125" height="125" rx="6" fill="white" />
								<image x="551" y="23" width={125} xlinkHref="https://res.cloudinary.com/dm1muiikf/image/upload/v1690872419/website/case_study/iis.png" />
								<rect x="551" y="0.5" width="126" height="126" rx="6.5" stroke="#ADADAD" stroke-opacity="0.6" />
							</g>
							<g filter="url(#filter3_d_484_1188)" class="block top topFour">
								<rect x="763.75" y="1" width="125" height="125" rx="6" fill="white" />
								<image x="763" y="23" width={115} xlinkHref="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/csd/Net_06.png" />
								<rect x="763.25" y="0.5" width="126" height="126" rx="6.5" stroke="#ADADAD" stroke-opacity="0.6" />
							</g>
							<g filter="url(#filter4_d_484_1188)" class="block top topFive">
								<rect x="976" y="1" width="125" height="125" rx="6" fill="white" />
								<image x="980" y="18" width={108} xlinkHref="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/csd/react.png" />
								<rect x="975.5" y="0.5" width="126" height="126" rx="6.5" stroke="#ADADAD" stroke-opacity="0.6" />
							</g>
							<g filter="url(#filter6_d_484_1188)" class="block bottom bottomTwo">
								<rect x="525" y="640" width="180" height="176" rx="6" fill="white" />
								<image x="528" y="675" width={175} xlinkHref="https://res.cloudinary.com/dm1muiikf/image/upload/v1690872420/website/case_study/travel.png" />
								<rect x="524.5" y="639.5" width="181" height="177" rx="6.5" stroke="#ADADAD" stroke-opacity="0.6" />
							</g>
							<defs>
								<filter id="filter0_d_484_1188" x="126" y="0" width="130" height="130" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
									<feFlood flood-opacity="0" result="BackgroundImageFix" />
									<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
									<feOffset dx="3" dy="3" />
									<feColorMatrix type="matrix" values="0 0 0 0 0.0340278 0 0 0 0 0.157404 0 0 0 0 0.291667 0 0 0 0.15 0" />
									<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_484_1188" />
									<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_484_1188" result="shape" />
								</filter>
								<filter id="filter1_d_484_1188" x="338.25" y="0" width="130" height="130" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
									<feFlood flood-opacity="0" result="BackgroundImageFix" />
									<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
									<feOffset dx="3" dy="3" />
									<feColorMatrix type="matrix" values="0 0 0 0 0.0340278 0 0 0 0 0.157404 0 0 0 0 0.291667 0 0 0 0.15 0" />
									<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_484_1188" />
									<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_484_1188" result="shape" />
								</filter>
								<filter id="filter2_d_484_1188" x="550.5" y="0" width="130" height="130" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
									<feFlood flood-opacity="0" result="BackgroundImageFix" />
									<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
									<feOffset dx="3" dy="3" />
									<feColorMatrix type="matrix" values="0 0 0 0 0.0340278 0 0 0 0 0.157404 0 0 0 0 0.291667 0 0 0 0.15 0" />
									<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_484_1188" />
									<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_484_1188" result="shape" />
								</filter>
								<filter id="filter3_d_484_1188" x="762.75" y="0" width="130" height="130" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
									<feFlood flood-opacity="0" result="BackgroundImageFix" />
									<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
									<feOffset dx="3" dy="3" />
									<feColorMatrix type="matrix" values="0 0 0 0 0.0340278 0 0 0 0 0.157404 0 0 0 0 0.291667 0 0 0 0.15 0" />
									<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_484_1188" />
									<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_484_1188" result="shape" />
								</filter>
								<filter id="filter4_d_484_1188" x="975" y="0" width="130" height="130" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
									<feFlood flood-opacity="0" result="BackgroundImageFix" />
									<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
									<feOffset dx="3" dy="3" />
									<feColorMatrix type="matrix" values="0 0 0 0 0.0340278 0 0 0 0 0.157404 0 0 0 0 0.291667 0 0 0 0.15 0" />
									<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_484_1188" />
									<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_484_1188" result="shape" />
								</filter>
								<filter id="filter5_d_484_1188" x="259" y="639" width="185" height="181" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
									<feFlood flood-opacity="0" result="BackgroundImageFix" />
									<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
									<feOffset dx="3" dy="3" />
									<feColorMatrix type="matrix" values="0 0 0 0 0.0340278 0 0 0 0 0.157404 0 0 0 0 0.291667 0 0 0 0.15 0" />
									<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_484_1188" />
									<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_484_1188" result="shape" />
								</filter>
								<filter id="filter6_d_484_1188" x="524" y="639" width="185" height="181" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
									<feFlood flood-opacity="0" result="BackgroundImageFix" />
									<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
									<feOffset dx="3" dy="3" />
									<feColorMatrix type="matrix" values="0 0 0 0 0.0340278 0 0 0 0 0.157404 0 0 0 0 0.291667 0 0 0 0.15 0" />
									<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_484_1188" />
									<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_484_1188" result="shape" />
								</filter>
								<filter id="filter7_d_484_1188" x="789" y="639" width="185" height="181" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
									<feFlood flood-opacity="0" result="BackgroundImageFix" />
									<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
									<feOffset dx="3" dy="3" />
									<feColorMatrix type="matrix" values="0 0 0 0 0.0340278 0 0 0 0 0.157404 0 0 0 0 0.291667 0 0 0 0.15 0" />
									<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_484_1188" />
									<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_484_1188" result="shape" />
								</filter>
							</defs>
						</svg>
					</div>

					{/* **********************  Mobile view casestudy ********************** */}
					<div class="" id="casestudy">
						<div id="demo">
							<svg viewBox="0 0 630 1630" fill="none" xmlns="http://www.w3.org/2000/svg" class="mt-5">
								<g opacity="0.15" class="line bottom bottomTwo">
									<path d="M315 104L315 1468" stroke="#2378D5" stroke-width="12" />
								</g>
								<g opacity="0.15" class="line top topTwo">
									<path d="M315 104L315 1468" stroke="#2378D5" stroke-width="12" />
								</g>
								<g class="dots top dotsTwo">
									<path d="M315 104L315 468" stroke="#2378D5" stroke-width="4" />
								</g>
								<g class="dots top dotsThree">
									<path d="M315 104L315 468" stroke="#2378D5" stroke-width="4" />
								</g>
								<g class="dots top dotsFour">
									<path d="M315 104L315 468" stroke="#2378D5" stroke-width="4" />
								</g>
								<g class="dots bottom dotsOne">
									<path d="M315 661L315.5 1297" stroke="#2378D5" stroke-width="4" />
								</g>
								<g class="dots bottom dotsTwo">
									<path d="M315 661L315.5 1297" stroke="#2378D5" stroke-width="4" />
								</g>
								<g class="dots bottom dotsThree">
									<path d="M315 661L315.5 1297" stroke="#2378D5" stroke-width="4" />
								</g>
								<g class="block top topOne">
									<rect x="250" y="1" width="125" height="125" rx="6" fill="white" />
									<image x="262" y="24" width={101} xlinkHref="https://res.cloudinary.com/dm1muiikf/image/upload/v1690872419/website/case_study/aws.png" />
									<rect x="250.5" y="0.5" width="126" height="126" rx="6.5" stroke="#ADADAD" stroke-opacity="0.6" />
								</g>
								<g class="block top topTwo">
									<rect x="250" y="228" width="125" height="125" rx="6" fill="white" />
									<image x="259" y="229" width={115} xlinkHref="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/csd/sql.png" />
									<rect x="250.5" y="228.5" width="126" height="126" rx="6.5" stroke="#ADADAD" stroke-opacity="0.6" />
								</g>
								<g class="block top topThree">
									<rect x="250" y="455" width="125" height="125" rx="6" fill="white" />
									<image x="251" y="459" width={125} xlinkHref="https://res.cloudinary.com/dm1muiikf/image/upload/v1690872419/website/case_study/iis.png" />
									<rect x="250.5" y="455.5" width="126" height="126" rx="6.5" stroke="#ADADAD" stroke-opacity="0.6" />
								</g>
								<g class="block top topFour">
									<rect x="250" y="682" width="125" height="125" rx="6" fill="white" />
									<image x="251" y="709" width={115} xlinkHref="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/csd/Net_06.png" />
									<rect x="250.25" y="682.5" width="126" height="126" rx="6.5" stroke="#ADADAD" stroke-opacity="0.6" />
								</g>
								<g class="block top topFive">
									<rect x="250" y="909" width="125" height="125" rx="6" fill="white" />
									<image x="266" y="920" width={95} xlinkHref="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/csd/react.png" />
									<rect x="250.5" y="909.5" width="126" height="126" rx="6.5" stroke="#ADADAD" stroke-opacity="0.6" />
								</g>

								<svg id="svg" viewBox="0 0 320 1" width="71%" >
									<circle class="background" cx="220" cy="290" r="35" stroke="" fill="#0051AA" />
									<circle class="background" cx="220" cy="290" r="75" stroke="" fill="#B3D1EF" />
									<circle class="background" cx="220" cy="290" r="55" stroke="" fill="#0051AA" />
									<foreignObject x="170" y="260" width="100" height="100">
										<div className="fs-6 text-white text-center">
											Travel Case Study
										</div>
									</foreignObject>
									<circle class="background" cx="220" cy="290" r="95" stroke="" fill="rgba(0, 74, 171, 0.1)" />
								</svg>

								<g class="block bottom bottomTwo">
									<rect x="220" y="1436" width="180" height="176" rx="6" fill="white" />
									<image x="225" y="1457" width={175} xlinkHref="https://res.cloudinary.com/dm1muiikf/image/upload/v1690872420/website/case_study/travel.png" />
									<rect x="220.5" y="1436.5" width="181" height="177" rx="6.5" stroke="#ADADAD" stroke-opacity="0.6" />
								</g>
								<defs>
									<filter id="filter0_d_484_1188" x="126" y="0" width="130" height="130" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
										<feFlood flood-opacity="0" result="BackgroundImageFix" />
										<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
										<feOffset dx="3" dy="3" />
										<feColorMatrix type="matrix" values="0 0 0 0 0.0340278 0 0 0 0 0.157404 0 0 0 0 0.291667 0 0 0 0.15 0" />
										<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_484_1188" />
										<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_484_1188" result="shape" />
									</filter>
									<filter id="filter1_d_484_1188" x="338.25" y="0" width="130" height="130" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
										<feFlood flood-opacity="0" result="BackgroundImageFix" />
										<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
										<feOffset dx="3" dy="3" />
										<feColorMatrix type="matrix" values="0 0 0 0 0.0340278 0 0 0 0 0.157404 0 0 0 0 0.291667 0 0 0 0.15 0" />
										<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_484_1188" />
										<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_484_1188" result="shape" />
									</filter>
									<filter id="filter2_d_484_1188" x="550.5" y="0" width="130" height="130" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
										<feFlood flood-opacity="0" result="BackgroundImageFix" />
										<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
										<feOffset dx="3" dy="3" />
										<feColorMatrix type="matrix" values="0 0 0 0 0.0340278 0 0 0 0 0.157404 0 0 0 0 0.291667 0 0 0 0.15 0" />
										<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_484_1188" />
										<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_484_1188" result="shape" />
									</filter>
									<filter id="filter3_d_484_1188" x="762.75" y="0" width="130" height="130" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
										<feFlood flood-opacity="0" result="BackgroundImageFix" />
										<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
										<feOffset dx="3" dy="3" />
										<feColorMatrix type="matrix" values="0 0 0 0 0.0340278 0 0 0 0 0.157404 0 0 0 0 0.291667 0 0 0 0.15 0" />
										<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_484_1188" />
										<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_484_1188" result="shape" />
									</filter>
									<filter id="filter4_d_484_1188" x="975" y="0" width="130" height="130" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
										<feFlood flood-opacity="0" result="BackgroundImageFix" />
										<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
										<feOffset dx="3" dy="3" />
										<feColorMatrix type="matrix" values="0 0 0 0 0.0340278 0 0 0 0 0.157404 0 0 0 0 0.291667 0 0 0 0.15 0" />
										<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_484_1188" />
										<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_484_1188" result="shape" />
									</filter>
									<filter id="filter5_d_484_1188" x="259" y="639" width="185" height="181" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
										<feFlood flood-opacity="0" result="BackgroundImageFix" />
										<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
										<feOffset dx="3" dy="3" />
										<feColorMatrix type="matrix" values="0 0 0 0 0.0340278 0 0 0 0 0.157404 0 0 0 0 0.291667 0 0 0 0.15 0" />
										<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_484_1188" />
										<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_484_1188" result="shape" />
									</filter>
									<filter id="filter6_d_484_1188" x="524" y="639" width="185" height="181" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
										<feFlood flood-opacity="0" result="BackgroundImageFix" />
										<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
										<feOffset dx="3" dy="3" />
										<feColorMatrix type="matrix" values="0 0 0 0 0.0340278 0 0 0 0 0.157404 0 0 0 0 0.291667 0 0 0 0.15 0" />
										<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_484_1188" />
										<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_484_1188" result="shape" />
									</filter>
									<filter id="filter7_d_484_1188" x="789" y="639" width="185" height="181" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
										<feFlood flood-opacity="0" result="BackgroundImageFix" />
										<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
										<feOffset dx="3" dy="3" />
										<feColorMatrix type="matrix" values="0 0 0 0 0.0340278 0 0 0 0 0.157404 0 0 0 0 0.291667 0 0 0 0.15 0" />
										<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_484_1188" />
										<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_484_1188" result="shape" />
									</filter>
								</defs>
							</svg>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Travel;