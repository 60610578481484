import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

// ================================|| DigitalRetail ||================================ //

const DigitalRetail = () => {
	return (
		<>
			<Helmet>
				<title>Digital Retail - The Bridge Code</title>
			</Helmet>
			<div className='container-fluid p-0 '>
				<div class="position-relative overflow-hidden text-center supplychain">
					<div class="col-md-5 p-lg-5 mx-auto my-1">
						<h1 class="display-2 fw-bolder text-white title-c">Digital Retail Case Study</h1>
						<Link to='/contact-us' class="cl-button -primary -big rounded-3">Contact Us</Link>
					</div>
				</div>

				<div className="container">
					<div>
						<p style={{ marginTop: "50px" }}></p>
						<p>A big-box retail chain with thousands of locations throughout India has been working on a sweeping digital transformation. For the company, it's been a
							multiyear effort to create a seamless digital customer experience. A top objective has been to empower the finance and accounting team with automation and
							analytics tools and techniques, enabling them to move away from legacy, manual processes toward efficiency and a competitive edge.
						</p>
					</div>

					<div>
						<h4 className="text-h4">The Goal</h4>
						<p>A digital overhaul focused on individual team members that allows them to design and access their own real-time, automated views of critical information, So
							they’re able to play a more strategic role.
						</p>
						<p>Prior to the retailer’s current digital transformation efforts, leaders had worked to transform enterprise applications, but the upgrades were not reaching
							every corner of the business to fully meet the needs of the high-growth company. They started over on a project-by-project basis, modernizing each area as
							they progressed. Management historically relied on time-intensive, manual spreadsheets but was aware of the opportunities automation techniques could provide.
						</p>
					</div>

					<div>
						<h4 className="text-h4">Solution</h4>
						<p>Modernizing internally with automation The retailer launched a pilot to build 20 automation workflows for the finance team. For inspiration and training,
							The Bridge Code applied way of working, combining business strategy, Design and Technology , and staff members attended courses for hands-on experience.
						</p>
					</div>

					<section className="steps steps-area">
						<div className="container">
							<ul className="timelines">
								<li className="timelines-box wow fadeInLeft">
									<i className="icon-layers"></i>
									<p className="timelines-title">Eliminating manual tasks to create a more tech-enabled future</p>
									<p className="timelines-details">A sprint included exploring potential methodologies and possibilities, which created the energy and engagement necessary to move quickly from planning to action. Functional leaders identified their most painful manual processes and then mapped workflows, creating the basis for successful rebuilds.</p>
									<span className="deee">1</span>
								</li>
								<li className="timelines-box wow fadeInRight">
									<i className="icon-chat"></i>
									<p className="timelines-title">Simplified processes lead to new possibilities</p>
									<p className="timelines-details">Employees began to understand how new workflows could simplify their jobs and were active in refining the workflows to create a better digital experience and improve efficiency.</p>
									<span className="deee">2</span>
								</li>
								<li className="timelines-box  wow fadeInLeft">
									<i className="icon-tools"></i>
									<p className="timelines-title">Citizen-led automation</p>
									<p className="timelines-details">The effort focused on gaining experience and delivering results, A platform that allows end-users to automate data intensive work, obtaining automated insights and analytics for faster business outcomes, supporting the business case for modern methods.</p>
									<span className="deee">3</span>
								</li>
							</ul>
						</div>
					</section>

					<div>
						<h4 className="text-h4">Results</h4>
						<p>In a matter of weeks, the retailer was able to introduce capabilities that could remove thousands of hours of manual processes per year. Additionally, the
							company enabled faster decision-making with more robust and current data. More importantly, it set the precedent for the team to begin a full “citizen
							automation” mindset shift.</p>
						<p>As an example, reconciliations of payments in the core ERP platform were done daily in a manual, spreadsheet-managed process. Leveraging and the cloud data
							platform, this process was automated from taking approximately 500 hours on an annual basis to minutes via The Bridge Code Automation Software.</p>
						<p>In a short trial of only Eight weeks, the company moved from planning to action, increasing employee productivity and morale for 100% of use cases identified by process.</p>
						<p style={{ marginTop: "50px" }}></p>
					</div>
				</div >

				<div class="container-cs" id="case_study">
					<div class="circles-container">
						<div class="circles circleOne"></div>
						<div class="circles circleTwo"></div>
						<div class="circles circleThree"></div>
						<div class="circles circleFour"></div>
						<div class="logo">Digital Retail Case Study</div>
					</div>
					<svg width="1230" height="820" viewBox="0 0 1230 820" fill="none" xmlns="http://www.w3.org/2000/svg" class="mx-auto">
						<g opacity="0.15" class="line bottom bottomTwo">
							<path d="M615.5 661L615.5 497" stroke="#2378D5" stroke-width="12" />
						</g>
						<g opacity="0.15" class="line top topFour">
							<path d="M829 105L829 160.231C829 171.277 820.046 180.231 809 180.231L635 180.231C623.954 180.231 615 189.185 615 200.231L615 268" stroke="#2378D5" stroke-width="12" />
						</g>
						<g opacity="0.15" class="line top topThree">
							<path d="M615 104L615 268" stroke="#2378D5" stroke-width="12" />
						</g>
						<g opacity="0.15" class="line top topTwo">
							<path d="M402 106L402 160.769C402 171.815 410.954 180.769 422 180.769L595 180.769C606.046 180.769 615 189.724 615 200.769L615 268" stroke="#2378D5" stroke-width="12" />
						</g>
						<g opacity="0.15" class="line top topFive">
							<path d="M1041 105L1041 160.231C1041 171.276 1032.05 180.231 1021 180.231L635 180.231C623.954 180.231 615 189.185 615 200.231L615 268" stroke="#2378D5" stroke-width="12" />
						</g>
						<g opacity="0.15" class="line top topOne">
							<path d="M190 106L190 160.769C190 171.815 198.954 180.769 210 180.769L594.503 180.769C605.549 180.769 614.503 189.724 614.503 200.769L614.503 268" stroke="#2378D5" stroke-width="12" />
						</g>
						<g class="dots top dotsOne">
							<path d="M190 106L190 160.769C190 171.815 198.954 180.769 210 180.769L594.503 180.769C605.549 180.769 614.503 189.724 614.503 200.769L614.503 268" stroke="#2378D5" stroke-width="4" />
						</g>
						<g class="dots top dotsFive">
							<path d="M1041 105L1041 160.231C1041 171.276 1032.05 180.231 1021 180.231L635 180.231C623.954 180.231 615 189.185 615 200.231L615 268" stroke="#2378D5" stroke-width="4" />
						</g>
						<g class="dots top dotsTwo">
							<path d="M402 106L402 160.769C402 171.815 410.954 180.769 422 180.769L595 180.769C606.046 180.769 615 189.724 615 200.769L615 268" stroke="#2378D5" stroke-width="4" />
						</g>
						<g class="dots top dotsThree">
							<path d="M615 104L615 268" stroke="#2378D5" stroke-width="4" />
						</g>
						<g class="dots top dotsFour">
							<path d="M829 105L829 160.231C829 171.277 820.046 180.231 809 180.231L635 180.231C623.954 180.231 615 189.185 615 200.231L615 268" stroke="#2378D5" stroke-width="4" />
						</g>
						<g class="dots bottom dotsOne">
							<path d="M353.5 659.5L353.5 604.5C353.5 593.455 362.454 584.5 373.5 584.5L595 584.5C606.046 584.5 615 575.546 615 564.5L615 497" stroke="#2378D5" stroke-width="4" />
						</g>
						<g class="dots bottom dotsTwo">
							<path d="M615.5 661L615.5 497" stroke="#2378D5" stroke-width="4" />
						</g>
						<g class="dots bottom dotsThree">
							<path d="M877 659.5L877 604.5C877 593.454 868.046 584.5 857 584.5L635.5 584.5C624.454 584.5 615.5 575.545 615.5 564.5L615.5 497" stroke="#2378D5" stroke-width="4" />
						</g>
						<g filter="url(#filter0_d_484_1188)" class="block top topOne">
							<rect x="127" y="1" width="125" height="125" rx="6" fill="white" />
							<image x="150" y="23" width={85} xlinkHref="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/csd/python.png" />
							<rect x="126.5" y="0.5" width="126" height="126" rx="6.5" stroke="#ADADAD" stroke-opacity="0.6" />
						</g>

						<g filter="url(#filter1_d_484_1188)" class="block top topTwo">
							<rect x="339.25" y="1" width="125" height="125" rx="6" fill="white" />
							<image x="343" y="1" width={115} xlinkHref="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/csd/sql.png" />
							<rect x="338.75" y="0.5" width="126" height="126" rx="6.5" stroke="#ADADAD" stroke-opacity="0.6" />
						</g>
						<g filter="url(#filter2_d_484_1188)" class="block top topThree">
							<rect x="551.5" y="1" width="125" height="125" rx="6" fill="white" />
							<image x="551" y="23" width={125} xlinkHref="https://res.cloudinary.com/dm1muiikf/image/upload/v1690872419/website/case_study/iis.png" />
							<rect x="551" y="0.5" width="126" height="126" rx="6.5" stroke="#ADADAD" stroke-opacity="0.6" />
						</g>
						<g filter="url(#filter3_d_484_1188)" class="block top topFour">
							<rect x="763.75" y="1" width="125" height="125" rx="6" fill="white" />
							<image x="763" y="23" width={115} xlinkHref="	https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/csd/red_query.svg" />
							<rect x="763.25" y="0.5" width="126" height="126" rx="6.5" stroke="#ADADAD" stroke-opacity="0.6" />
						</g>
						<g filter="url(#filter4_d_484_1188)" class="block top topFive">
							<rect x="976" y="1" width="125" height="125" rx="6" fill="white" />
							<image x="989" y="18" width={95} xlinkHref="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/csd/html.png" />
							<rect x="975.5" y="0.5" width="126" height="126" rx="6.5" stroke="#ADADAD" stroke-opacity="0.6" />
						</g>

						<g filter="url(#filter6_d_484_1188)" class="block bottom bottomTwo">
							<rect x="525" y="640" width="180" height="176" rx="6" fill="white" />
							<image x="528" y="660" width={175} xlinkHref="https://res.cloudinary.com/dm1muiikf/image/upload/v1690872419/website/case_study/retail.png" />
							<rect x="524.5" y="639.5" width="181" height="177" rx="6.5" stroke="#ADADAD" stroke-opacity="0.6" />
						</g>
						<defs>
							<filter id="filter0_d_484_1188" x="126" y="0" width="130" height="130" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
								<feFlood flood-opacity="0" result="BackgroundImageFix" />
								<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
								<feOffset dx="3" dy="3" />
								<feColorMatrix type="matrix" values="0 0 0 0 0.0340278 0 0 0 0 0.157404 0 0 0 0 0.291667 0 0 0 0.15 0" />
								<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_484_1188" />
								<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_484_1188" result="shape" />
							</filter>
							<filter id="filter1_d_484_1188" x="338.25" y="0" width="130" height="130" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
								<feFlood flood-opacity="0" result="BackgroundImageFix" />
								<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
								<feOffset dx="3" dy="3" />
								<feColorMatrix type="matrix" values="0 0 0 0 0.0340278 0 0 0 0 0.157404 0 0 0 0 0.291667 0 0 0 0.15 0" />
								<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_484_1188" />
								<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_484_1188" result="shape" />
							</filter>
							<filter id="filter2_d_484_1188" x="550.5" y="0" width="130" height="130" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
								<feFlood flood-opacity="0" result="BackgroundImageFix" />
								<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
								<feOffset dx="3" dy="3" />
								<feColorMatrix type="matrix" values="0 0 0 0 0.0340278 0 0 0 0 0.157404 0 0 0 0 0.291667 0 0 0 0.15 0" />
								<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_484_1188" />
								<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_484_1188" result="shape" />
							</filter>
							<filter id="filter3_d_484_1188" x="762.75" y="0" width="130" height="130" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
								<feFlood flood-opacity="0" result="BackgroundImageFix" />
								<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
								<feOffset dx="3" dy="3" />
								<feColorMatrix type="matrix" values="0 0 0 0 0.0340278 0 0 0 0 0.157404 0 0 0 0 0.291667 0 0 0 0.15 0" />
								<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_484_1188" />
								<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_484_1188" result="shape" />
							</filter>
							<filter id="filter4_d_484_1188" x="975" y="0" width="130" height="130" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
								<feFlood flood-opacity="0" result="BackgroundImageFix" />
								<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
								<feOffset dx="3" dy="3" />
								<feColorMatrix type="matrix" values="0 0 0 0 0.0340278 0 0 0 0 0.157404 0 0 0 0 0.291667 0 0 0 0.15 0" />
								<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_484_1188" />
								<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_484_1188" result="shape" />
							</filter>
							<filter id="filter5_d_484_1188" x="259" y="639" width="185" height="181" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
								<feFlood flood-opacity="0" result="BackgroundImageFix" />
								<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
								<feOffset dx="3" dy="3" />
								<feColorMatrix type="matrix" values="0 0 0 0 0.0340278 0 0 0 0 0.157404 0 0 0 0 0.291667 0 0 0 0.15 0" />
								<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_484_1188" />
								<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_484_1188" result="shape" />
							</filter>
							<filter id="filter6_d_484_1188" x="524" y="639" width="185" height="181" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
								<feFlood flood-opacity="0" result="BackgroundImageFix" />
								<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
								<feOffset dx="3" dy="3" />
								<feColorMatrix type="matrix" values="0 0 0 0 0.0340278 0 0 0 0 0.157404 0 0 0 0 0.291667 0 0 0 0.15 0" />
								<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_484_1188" />
								<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_484_1188" result="shape" />
							</filter>
							<filter id="filter7_d_484_1188" x="789" y="639" width="185" height="181" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
								<feFlood flood-opacity="0" result="BackgroundImageFix" />
								<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
								<feOffset dx="3" dy="3" />
								<feColorMatrix type="matrix" values="0 0 0 0 0.0340278 0 0 0 0 0.157404 0 0 0 0 0.291667 0 0 0 0.15 0" />
								<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_484_1188" />
								<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_484_1188" result="shape" />
							</filter>
						</defs>
					</svg>
				</div>

				{/* **********************  Mobile view casestudy ********************** */}
				<div class="" id="casestudy">
					<div id="demo">
						<svg viewBox="0 0 630 1630" fill="none" xmlns="http://www.w3.org/2000/svg" class="mt-5">
							<g opacity="0.15" class="line bottom bottomTwo">
								<path d="M315 104L315 1468" stroke="#2378D5" stroke-width="12" />
							</g>
							<g opacity="0.15" class="line top topTwo">
								<path d="M315 104L315 1468" stroke="#2378D5" stroke-width="12" />
							</g>
							<g class="dots top dotsTwo">
								<path d="M315 104L315 468" stroke="#2378D5" stroke-width="4" />
							</g>
							<g class="dots top dotsThree">
								<path d="M315 104L315 468" stroke="#2378D5" stroke-width="4" />
							</g>
							<g class="dots top dotsFour">
								<path d="M315 104L315 468" stroke="#2378D5" stroke-width="4" />
							</g>
							<g class="dots bottom dotsOne">
								<path d="M315 661L315.5 1297" stroke="#2378D5" stroke-width="4" />
							</g>
							<g class="dots bottom dotsTwo">
								<path d="M315 661L315.5 1297" stroke="#2378D5" stroke-width="4" />
							</g>
							<g class="dots bottom dotsThree">
								<path d="M315 661L315.5 1297" stroke="#2378D5" stroke-width="4" />
							</g>
							<g class="block top topOne">
								<rect x="250" y="1" width="125" height="125" rx="6" fill="white" />
								<image x="259" y="20" width={101} xlinkHref="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/csd/python.png" />
								<rect x="250.5" y="0.5" width="126" height="126" rx="6.5" stroke="#ADADAD" stroke-opacity="0.6" />
							</g>

							<g class="block top topTwo">
								<rect x="250" y="228" width="125" height="125" rx="6" fill="white" />
								<image x="255" y="229" width={115} xlinkHref="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/csd/sql.png" />
								<rect x="250.5" y="228.5" width="126" height="126" rx="6.5" stroke="#ADADAD" stroke-opacity="0.6" />
							</g>
							<g class="block top topThree">
								<rect x="250" y="455" width="125" height="125" rx="6" fill="white" />
								<image x="251" y="459" width={125} xlinkHref="https://res.cloudinary.com/dm1muiikf/image/upload/v1690872419/website/case_study/iis.png" />
								<rect x="250.5" y="455.5" width="126" height="126" rx="6.5" stroke="#ADADAD" stroke-opacity="0.6" />
							</g>
							<g class="block top topFour">
								<rect x="250" y="682" width="125" height="125" rx="6" fill="white" />
								<image x="255" y="691" width={115} xlinkHref="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/csd/red_query.svg" />
								<rect x="250.25" y="682.5" width="126" height="126" rx="6.5" stroke="#ADADAD" stroke-opacity="0.6" />
							</g>
							<g class="block top topFive">
								<rect x="250" y="909" width="125" height="125" rx="6" fill="white" />
								<image x="266" y="920" width={95} xlinkHref="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/csd/html.png" />
								<rect x="250.5" y="909.5" width="126" height="126" rx="6.5" stroke="#ADADAD" stroke-opacity="0.6" />
							</g>

							<svg id="svg" viewBox="0 0 320 1" width="71%" >
								<circle class="background" cx="220" cy="290" r="35" stroke="" fill="#0051AA" />
								<circle class="background" cx="220" cy="290" r="75" stroke="" fill="#B3D1EF" />
								<circle class="background" cx="220" cy="290" r="55" stroke="" fill="#0051AA" />
								<foreignObject x="170" y="262" width="100" height="100">
									<div className="fs-6 text-white text-center">
										Digital Retail Case Study
									</div>
								</foreignObject>
								<circle class="background" cx="220" cy="290" r="95" stroke="" fill="rgba(0, 74, 171, 0.1)" />
							</svg>

							<g class="block bottom bottomTwo">
								<rect x="220" y="1436" width="180" height="176" rx="6" fill="white" />
								<image x="229" y="1457" width={175} xlinkHref="https://res.cloudinary.com/dm1muiikf/image/upload/v1690872419/website/case_study/retail.png" />
								<rect x="220.5" y="1436.5" width="181" height="177" rx="6.5" stroke="#ADADAD" stroke-opacity="0.6" />
							</g>
							<defs>
								<filter id="filter0_d_484_1188" x="126" y="0" width="130" height="130" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
									<feFlood flood-opacity="0" result="BackgroundImageFix" />
									<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
									<feOffset dx="3" dy="3" />
									<feColorMatrix type="matrix" values="0 0 0 0 0.0340278 0 0 0 0 0.157404 0 0 0 0 0.291667 0 0 0 0.15 0" />
									<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_484_1188" />
									<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_484_1188" result="shape" />
								</filter>
								<filter id="filter1_d_484_1188" x="338.25" y="0" width="130" height="130" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
									<feFlood flood-opacity="0" result="BackgroundImageFix" />
									<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
									<feOffset dx="3" dy="3" />
									<feColorMatrix type="matrix" values="0 0 0 0 0.0340278 0 0 0 0 0.157404 0 0 0 0 0.291667 0 0 0 0.15 0" />
									<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_484_1188" />
									<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_484_1188" result="shape" />
								</filter>
								<filter id="filter2_d_484_1188" x="550.5" y="0" width="130" height="130" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
									<feFlood flood-opacity="0" result="BackgroundImageFix" />
									<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
									<feOffset dx="3" dy="3" />
									<feColorMatrix type="matrix" values="0 0 0 0 0.0340278 0 0 0 0 0.157404 0 0 0 0 0.291667 0 0 0 0.15 0" />
									<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_484_1188" />
									<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_484_1188" result="shape" />
								</filter>
								<filter id="filter3_d_484_1188" x="762.75" y="0" width="130" height="130" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
									<feFlood flood-opacity="0" result="BackgroundImageFix" />
									<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
									<feOffset dx="3" dy="3" />
									<feColorMatrix type="matrix" values="0 0 0 0 0.0340278 0 0 0 0 0.157404 0 0 0 0 0.291667 0 0 0 0.15 0" />
									<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_484_1188" />
									<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_484_1188" result="shape" />
								</filter>
								<filter id="filter4_d_484_1188" x="975" y="0" width="130" height="130" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
									<feFlood flood-opacity="0" result="BackgroundImageFix" />
									<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
									<feOffset dx="3" dy="3" />
									<feColorMatrix type="matrix" values="0 0 0 0 0.0340278 0 0 0 0 0.157404 0 0 0 0 0.291667 0 0 0 0.15 0" />
									<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_484_1188" />
									<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_484_1188" result="shape" />
								</filter>
								<filter id="filter5_d_484_1188" x="259" y="639" width="185" height="181" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
									<feFlood flood-opacity="0" result="BackgroundImageFix" />
									<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
									<feOffset dx="3" dy="3" />
									<feColorMatrix type="matrix" values="0 0 0 0 0.0340278 0 0 0 0 0.157404 0 0 0 0 0.291667 0 0 0 0.15 0" />
									<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_484_1188" />
									<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_484_1188" result="shape" />
								</filter>
								<filter id="filter6_d_484_1188" x="524" y="639" width="185" height="181" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
									<feFlood flood-opacity="0" result="BackgroundImageFix" />
									<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
									<feOffset dx="3" dy="3" />
									<feColorMatrix type="matrix" values="0 0 0 0 0.0340278 0 0 0 0 0.157404 0 0 0 0 0.291667 0 0 0 0.15 0" />
									<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_484_1188" />
									<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_484_1188" result="shape" />
								</filter>
								<filter id="filter7_d_484_1188" x="789" y="639" width="185" height="181" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
									<feFlood flood-opacity="0" result="BackgroundImageFix" />
									<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
									<feOffset dx="3" dy="3" />
									<feColorMatrix type="matrix" values="0 0 0 0 0.0340278 0 0 0 0 0.157404 0 0 0 0 0.291667 0 0 0 0.15 0" />
									<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_484_1188" />
									<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_484_1188" result="shape" />
								</filter>
							</defs>
						</svg>
					</div>
				</div>
			</div>
		</>
	);
};

export default DigitalRetail;