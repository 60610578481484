import React from 'react';
import { BsFillHandThumbsUpFill, BsFillCheckCircleFill, BsFillMenuButtonWideFill } from "react-icons/bs";
import { AiOutlineMail, AiOutlineFileProtect } from "react-icons/ai";
import { RiHandCoinLine } from "react-icons/ri";
import Faq from "react-faq-component";
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";

const data = {
    title: <h1 className='pb-3 display-5 fw-bold title'>FAQ (How it works)</h1>,
    rows: [
        {
            title: <b>1. What is Hybrid App Development?</b>,
            content: `The hybrid app development methodology revolves around building an app with a single project using a framework that can target all needed platforms: Android, iOS, Windows, etc. <br />
            Hybrid software is very appealing because developers can create a single code base to be used for the app on all platforms. Hybrid app development is especially enticing for businesses since they can get in on the mobile market on all major platforms at once.`,
        },
        {
            title: <b>2. What makes Hybrid App Different from Native?</b>,
            content: `Hybrid development allows you to create your application once and then deploy it across different platforms, including iOS, Android, or Windows. This saves time for developers working on multiple projects designed specifically for each platform.<br />
            Native app development, on the other hand, is designed specifically for one platform and usually requires more time to develop. However, native apps typically perform better than hybrids because they have been specifically optimized for their target platform.<br />
            Moroever, Before Deciding between Hybrid and Native app development there are various factors to be kept in mind.`  ,
        },
        {
            title: <b>3. What is the Costs for Hybrid App Development?</b>,
            content: `It's always possible to give a Ballpark Estimation but No one can give you a flat price for hybrid app development. But, it is less expensive than it would be to make a native mobile app. The cost might depend on how long it takes to make the app and what features or design you want on it. `,
        },
        {
            title: <b>4. How to Choose the Right Hybrid Mobile App Development for your Business?</b>,
            content: `There is no one-size-fits-all answer to this question. You need to consider the needs of your business and find an app development firm that can meet those needs.<br />
            Here are a few factors you should keep in mind when making your decision:<br />
            a. The Platforms Your App Will Run On<br />
            b. The Features Your App Will Have<br />
            c. The Timeframe for Development<br />
            d. The Cost<br />
            e. The Level of Customer Service`,
        },
    ],
};

const styles = {
    bgColor: 'white',
    titleTextColor: "#000",
    rowTitleColor: "#000",
    rowContentColor: '#000',
    arrowColor: "#000",
    transitionDuration: "1s",
};

const config = {
    animate: true,
    // arrowIcon: "V",
    tabFocus: true,
    expandIcon: "+",
    collapseIcon: "-",
};

// ================================|| HybridAppDevelopment ||================================ //

const HybridAppDevelopment = () => {
    return (
        <>
            <Helmet>
				<title>Hybrid App Development - The Bridge Code</title>
			</Helmet>
            <div className='container-fluid p-0'>
                <div class="position-relative overflow-hidden text-center hybrid">
                    <div class="col-md-7 p-lg-5 mx-auto my-1">
                        <h1 class="display-2 fw-bolder text-white title-c">Hybrid Software Development</h1>
                        <p class="lead fw-normal text-light">One-Stop Destination for Hybrid App Development Solutions.</p>
                        <Link to='/contact-us'><button className='btn-change2'>Contact Us</button></Link>
                    </div>
                </div>
                <div className="container mt-5 mb-5">
                    <div className="row col-md-12">
                        <div className="col-md-6">
                            <div className="title"><h1>Overview</h1></div>
                            <p className="display-5">Consider. Shape. Influence. Shine.</p>
                            {/* <p className="fs-3 fw-bold">We Brainstorm and Develop according to your Business Needs.</p> */}
                            <p>Hybrid application development is about striking the right blend of native and web development technologies to bring your app to life.</p>
                            <p>To start the process, it’s important to remember that picking the right solution for your next application (or first) depends on several factors: Your budget,
                                the timeline, your team’s experience with the technology and, finally, your target audience.We would recommend developing a hybrid mobile app Unless you
                                are creating a highly performant game or other similar application, hybrid mobile app development might be the right choice because it offers an easier
                                development approach, cost savings, and compatibility across a variety of platforms. </p>
                            <ul className="">
                                <li className="list-group-item p-1 fs-5"><BsFillHandThumbsUpFill /> Expertise in top cross-development frameworks like Xamarin, Titanium, etc.</li>
                                <li className="list-group-item p-1 fs-5"><BsFillHandThumbsUpFill /> Deployed cross-platform apps in iOS & Android App stores.</li>
                                <li className="list-group-item p-1 fs-5"><BsFillHandThumbsUpFill /> Uses of latest and thrilling technology that gives smooth mobile experience Application maintenance and support.</li>
                                <li className="list-group-item p-1 fs-5"><BsFillHandThumbsUpFill /> Fast turnaround time with agile development.</li>
                            </ul>
                        </div>
                        <div className="col-md-6 align-self-center">
                            <img src="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/hybrid/hybrid.svg" alt="" className="img-fluid box bounce-1" />
                        </div>
                    </div>
                </div>

                <div className="container-fluid csd_serv ">
                    <div className="container">
                        <div className="row text-center justify-content-between">
                            <h1 className="display-2 fw-bold text-light title-c mb-5">Technologies We Use</h1>

                            <div className="three rounded shadow-lg text-white pt-3 mt-5" style={{ backgroundColor: "rgb(0,0,0,0.3)" }}>
                                <div className="mb-3"><img src="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/hybrid/Services-Icons-25.png" className="img-fluid bg-body" alt="React Native Development<" /></div>
                                <h4>React Native Development</h4>
                                <p className="text-light">React Native is an open-source JavaScript framework, designed for building apps on multiple platforms like iOS, Android, and also
                                    web applications, utilizing the very same code base. It brings all its glory to mobile app development.</p>
                                <div className="row px-2">
                                    <h4 className="text-start title">Features</h4>
                                    <ul className="col-md-5 text-start">
                                        <li className="list-group-item p-1"><BsFillCheckCircleFill /> Cost-Efficiency</li>
                                        <li className="list-group-item p-1"><BsFillCheckCircleFill /> Code Reusability</li>
                                        <li className="list-group-item p-1"><BsFillCheckCircleFill /> Native Look and Feel</li>
                                    </ul>
                                    <ul className="col-md-6 text-start">
                                        <li className="list-group-item p-1"><BsFillCheckCircleFill /> Live Reload</li>
                                        <li className="list-group-item p-1"><BsFillCheckCircleFill /> UI Focused</li>
                                        <li className="list-group-item p-1"><BsFillCheckCircleFill /> Third-Party Plugins</li>
                                        <li className="list-group-item p-1"><BsFillCheckCircleFill /> Large Community Support</li>
                                    </ul>
                                </div>
                            </div>

                            <div className="three rounded shadow-lg text-white pt-3 mt-5" >
                                <div className="mb-3"><img src="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/hybrid/Services-Icons-26.png" className="img-fluid bg-body" width={96} alt="Flutter App Development" /></div>
                                <h4>Flutter App Development</h4>
                                <p className="text-light">Flutter is the framework which helps them to create high-quality cross-platform mobile applications. This is the reason that you
                                    can get mobile apps of high performance and scalable solutions on an affordable budget.</p>
                                <div className="row px-2">
                                    <h4 className="text-start title">Features</h4>
                                    <ul className="col-md-5 text-start">
                                        <li className="list-group-item p-1"><BsFillCheckCircleFill /> Cross-platform development</li>
                                        <li className="list-group-item p-1"><BsFillCheckCircleFill /> Fast testing with hot reload</li>
                                        <li className="list-group-item p-1"><BsFillCheckCircleFill /> Vast Widget library</li>
                                    </ul>
                                    <ul className="col-md-6 text-start">
                                        <li className="list-group-item p-1"><BsFillCheckCircleFill /> Native Performance and Internationalization</li>
                                        <li className="list-group-item p-1"><BsFillCheckCircleFill /> Open-source</li>
                                        <li className="list-group-item p-1"><BsFillCheckCircleFill /> IDE and Firebase Support</li>
                                    </ul>
                                </div>
                            </div>

                            <div className="three rounded shadow-lg text-white pt-3 mt-5" >
                                <div className="mb-3"><img src="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/hybrid/ionic.png" className="img-fluid bg-body" width={96} alt="Ionic" /></div>
                                <h4>Ionic </h4>
                                <p className="text-light">Ionic is considered as one of the fastest cross-platform app development frameworks that are used for creating hybrid mobile apps. It has a robust framework that helps in building the Progressive Web Apps.</p>
                                <div className="row px-2">
                                    <h4 className="text-start title">Features</h4>
                                    <ul className="col-md-5 text-start">
                                        <li className="list-group-item p-1"><BsFillCheckCircleFill /> Web Standards-based</li>
                                        <li className="list-group-item p-1"><BsFillCheckCircleFill /> Simplicity</li>
                                        <li className="list-group-item p-1"><BsFillCheckCircleFill /> Shadow DOM</li>
                                    </ul>
                                    <ul className="col-md-6 text-start">
                                        <li className="list-group-item p-1"><BsFillCheckCircleFill /> Custom Elements</li>
                                        <li className="list-group-item p-1"><BsFillCheckCircleFill /> Ionic CLI</li>
                                        <li className="list-group-item p-1"><BsFillCheckCircleFill /> Framework Compatibility</li>
                                    </ul>
                                </div>
                            </div>

                            <div className="three rounded shadow-lg text-white pt-3 mt-5" style={{ backgroundColor: "rgb(0,0,0,0.3)" }}>
                                <div className="mb-3"><img src="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/csd/xamarin.png" className="img-fluid bg-body" width={96} alt="Xamarin" /></div>
                                <h4>Xamarin</h4>
                                <p className="text-light">We build and design power-packed Xamarin technology-based apps for businesses. We use simple yet visually appealing tools, perfect for the global market without sacrificing the quality of your products! If you are in search of Xamar in mobile app, then you have landed on the right page.</p>
                                <div className="row px-2">
                                    <h4 className="text-start title">Features</h4>
                                    <ul className="col-md-5 text-start">
                                        <li className="list-group-item p-1"><BsFillCheckCircleFill /> Native Multiplatform Support</li>
                                        <li className="list-group-item p-1"><BsFillCheckCircleFill /> flexibility</li>
                                        <li className="list-group-item p-1"><BsFillCheckCircleFill /> Easy Maintenance</li>
                                    </ul>
                                    <ul className="col-md-6 text-start">
                                        <li className="list-group-item p-1"><BsFillCheckCircleFill /> Code Reusability</li>
                                        <li className="list-group-item p-1"><BsFillCheckCircleFill /> Highly Safe and Secure</li>
                                        <li className="list-group-item p-1"><BsFillCheckCircleFill /> Quicker Development</li>
                                    </ul>
                                </div>
                            </div>

                            <div className="three rounded shadow-lg text-white pt-3 mt-5" style={{ backgroundColor: "rgb(0,0,0,0.3)" }}>
                                <div className="mb-3"><img src="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/hybrid/phonegap.png" className="img-fluid bg-body" width={155} alt="PhoneGap" /></div>
                                <h4>PhoneGap </h4>
                                <p className="text-light">Being the product of Adobe, PhoneGap is the fastest mobile application development framework. It allows the developers to reuse the written code for the need of building hybrid mobile apps.</p>
                                <div className="row px-2">
                                    <h4 className="text-start title">Features</h4>
                                    <ul className="col-md-5 text-start">
                                        <li className="list-group-item p-1"><BsFillCheckCircleFill /> Strong and Robust Backend</li>
                                        <li className="list-group-item p-1"><BsFillCheckCircleFill /> Open Source</li>
                                        <li className="list-group-item p-1"><BsFillCheckCircleFill /> Flexibility</li>
                                    </ul>
                                    <ul className="col-md-6 text-start">
                                        <li className="list-group-item p-1"><BsFillCheckCircleFill /> Ease of Development</li>
                                        <li className="list-group-item p-1"><BsFillCheckCircleFill /> Simple to work plugins</li>
                                        <li className="list-group-item p-1"><BsFillCheckCircleFill /> Backed by Apache, Powered by Apache Cordova</li>
                                    </ul>
                                </div>
                            </div>

                            <div className="three rounded shadow-lg text-white pt-3 mt-5" style={{ backgroundColor: "rgb(0,0,0,0.3)" }}>
                                <div className="mb-3"><img src="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/hybrid/Services-Icons-27.png" className="img-fluid bg-body" width={96} alt="Hybrid Mobile App Maintenance & Support" /></div>
                                <h4>Hybrid Mobile App Maintenance & Support</h4>
                                <p className="text-light">The Bridge Code provides 24*7 maintenance and support services which are really helpful for the smooth running of the cross-platform apps. Our developers stay updated to solve your problems.</p>
                            </div>

                            <div className="three rounded shadow-lg text-white pt-3 mt-5" >
                                <div className="mb-3"><img src="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/hybrid/Services-Icons-28.png" className="img-fluid bg-body" width={96} alt="Our Hybrid App Development Service Process" /></div>
                                <h4>Our Hybrid App Development Service Process</h4>
                                <p className="text-light">Hybrid mobile app development service is becoming increasingly popular, but it is also getting more competitive with different
                                    players in the field. We, at The Bridge Code use an agile Framework to deliver you the best outcomes possible. By not rushing the development process and
                                    ensuring quality at every stage with a streamlined development process.</p>
                            </div>

                            <div className="three rounded shadow-lg text-white pt-3 mt-5" style={{ backgroundColor: "rgb(0,0,0,0.3)" }}>
                                <div className="mb-3"><img src="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/hybrid/Services-Icons-29.png" className="img-fluid bg-body" width={96} alt="Project Analysis and Planning" /></div>
                                <h4>Project Analysis and Planning</h4>
                                <p className="text-light">Our process starts with the initial phase of analysis, which involves a thorough examination of project inputs and data. To make
                                    sure your project starts with all the crucial information needed, our team of hybrid app developers gathers all the relevant data and looks for answers to
                                    any queries they might have. This process includes an analysis of the project's scope, business requirements, operational requirements, technological
                                    requirements, and a feasibility and prototyping study. </p>
                            </div>

                            <div className="three rounded shadow-lg text-white pt-3 mt-5">
                                <div className="mb-3"><img src="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/hybrid/deployment_and_launch.png" className="img-fluid bg-body" width={96} alt="deployment and launch" /></div>
                                <h4>Deployment and Launch</h4>
                                <p className="text-light">The app is then optimized and is ready to be launched on the market for users to interact. We adhere to stringent delivery schedules and provide transparency regarding project status. We help you with the setup and deployment of your app on any selected platform after it has been satisfactorily created and tested.</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container p-5">
                    <div className="text-center mx-5">
                        <h1 className="title-c">Our Hybrid App Development Service Process</h1>
                        <p>Hybrid mobile app development service is becoming increasingly popular, but it is also getting more competitive with different players in the field. We, at Bridge Code, use an agile development methodology to deliver you the best outcomes possible. By not rushing the development process and ensuring quality at every stage with a streamlined development process, our hybrid app developers stand out from the competition.</p>
                    </div>
                    <div className="row text-center justify-content-between">
                        <div className="col-lg-5 col-md-8 col-sm-12 bg-body rounded shadow-lg mx-2 my-2 py-4">
                            <i className='boarder-2'><AiOutlineFileProtect style={{ color: '#20B2AA' }} size="96" /></i>
                            <h4>Design and Development</h4>
                            <p>We develop high-fidelity wireframes and prototypes after thoroughly understanding your requirements. The right people are the foundation of any development project and finding the appropriate people for the right jobs is of the utmost importance. We leave no stone unturned to secure the top talent in the industry. You can completely rely on us for all aspects of development, including planning the architecture and developing the functionalities.</p>
                        </div>
                        <div className="col-lg-5 col-md-8 col-sm-12 bg-body rounded shadow-lg mx-2 my-2 py-4">
                            <i className='boarder-2'><AiOutlineMail style={{ color: '#20B2AA' }} size="96" /></i>
                            {/* <img src="../csd/02.png" alt="Enterprise Software Development Services" width={100} className="img-fluid p-2" /> */}
                            <h4>Project Analysis and Planning</h4>
                            <p> Our process starts with the initial phase of analysis, which involves a thorough examination of project inputs and data. To make sure your project starts with all the crucial information needed, our team of hybrid app developers gathers all the relevant data and looks for answers to any queries they might have. This process includes - an analysis of the project's scope, business requirements, operational requirements, technological requirements, and a feasibility and prototyping study.</p>
                        </div>
                        <div className="col-lg-5 col-md-8 col-sm-12 bg-body rounded shadow-lg mx-2 my-2 py-4">
                            <i className='border-2 pt-2'><BsFillMenuButtonWideFill style={{ color: '#20B2AA' }} size="96" /></i>
                            <h4>Quality Analysis and Testing</h4>
                            <p> We value both our own and our customers' time. Your app is put through a rigorous testing process where several factors, including appearance and performance, are tested. We move forward with the quality analysis and testing after receiving your approval. We carry out thorough testing to look for bugs or other performance issues because we only want to give the best to our clients.</p>
                        </div>
                        <div className="col-lg-5 col-md-8 col-sm-12 bg-body rounded shadow-lg mx-2 my-2 py-4">
                            <i className='boarder-2'><RiHandCoinLine style={{ color: '#20B2AA' }} size="96" /></i>
                            <h4>Deployment and Launch</h4>
                            <p>The Hybrid app is then optimized and is then ready to be launched on the market for users to interact with and enjoy after receiving the quality team's approval. Our clients are always kept up to date on the development of the project. We adhere to stringent delivery schedules and provide transparency regarding project status. We help you with the setup and deployment of your app on any selected platform after it has been satisfactorily created and tested.</p>
                        </div>
                    </div>
                </div>

                {/* <!-- Cta--> */}
                <div className="container-fluid p-0 m-0">
                    <div className="parallax-container py-5">
                        <div className="parallax-content section-xl">
                            <div className="container">
                                <div className="row justify-content-end text-right">
                                    <div className="col-sm-7 text-center">
                                        <h3 className="display-4 fw-bold text-white mb-5">Why The Bridge Code?</h3>
                                        <p className='text-light mb-4'>The Bridge Code Technologies is a Firm where we provide software development, integrations, and resources you need to compete
                                            in the Fast changing world. We believe in the power of marketing, sales, content management and great customer service are key to success in any
                                            Business. We have started a New Approach for our Clients where they can see the Potential of our Skills and Software. Try our Free Demo! </p>
                                        <p className='mt-3 text-white fs-4 fw-bold'>If you have the Passion, We have the Plan!!</p>
                                        <div className="mt-4">
                                            <Link to='/solution' className="cl-button -primary -small mx-1">Try Free Demo</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container pt-5 pb-5">
                    <div className="col-md-12 ">
                        <div className="col-md-8 mx-auto">
                            <Faq data={data} styles={styles} config={config} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default HybridAppDevelopment;