import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

// ================================|| SupplyChain ||================================ //

const SupplyChain = () => {
	return (
		<>
			<Helmet>
				<title>Supply Chain & Logistics - The Bridge Code</title>
			</Helmet>
			<div className='container-fluid p-0 '>
				<div class="position-relative overflow-hidden text-center supplychain">
					<div class="col-md-5 p-lg-5 mx-auto my-1">
						<h1 class="display-2 fw-bolder text-white title-c">Supply Chain Case Study</h1>
						<Link to='/contact-us' class="cl-button -primary -big rounded-3">Contact Us</Link>
					</div>
				</div>

				<div className="container">
					<div>
						<p style={{ marginTop: "50px" }}></p>
						<p>The cargo shipping industry has seen skyrocketing demand in the last several years and keeping up with it has strained global supply chains. Sea Shipping and Logistics is one of
							the largest less-than-container-load shippers in the world, with 25 years of experience, servicing close to 2000 trade routes across 50 countries and 25,000 port across the
							globe.</p>
					</div>
					<div>
						<h4 className="text-h4">Challenge </h4>
						<p>Sea Shipping and Logistics operates a 1000-person team out of more than 50 offices across the world. These locations have accelerated growth into new markets. But
							they have also brought new challenges to operations.</p>
						<p>Locations were run as independent units rather than as parts of an interconnected global business. Sales teams traditionally focused on order fulfillment and
							issue resolution, rather than customer pipeline building; and in more mature markets, like Europe and the Asia, resource utilization wasn’t optimized. Some
							locations were operating beyond capacity while others were being underutilized.</p>
						<p>Founder and chairman knew Sea Shipping and Logistics needed a new growth strategy. Founder said “We believed the company could do far more in terms of market
							share,” Chairman said “We needed a digital strategy that would make us future-ready and help build the company into a global leader in the logistics industry.”
						</p>
					</div>

					<div>
						<h4 className="text-h4">Solution </h4>
						<p>As the pandemic began upending the global logistics industry, Sea Shipping and Logistics worked with The Bridge Code to focus their goals into a resilient,
							long-term growth strategy that built operational cohesion and created digital solutions to give them a competitive advantage.</p>
						<p>We helped them think through what organizational changes and digital solutions could make this happen.</p>
						<p>After an intensive diagnostic sprint, The Bridge Code and Sea Shipping and Logistics developed a transformation plan that featured four central pillars:
							improving sales efficiency, transforming operations using analytics, optimizing the customer journey, and creating a future-ready organization.</p>
					</div>

					<div>
						<h4 className="text-h4">How it was Done</h4>
						<p>Sea Shipping and Logistics helped The Bridge Code identify more than 15 geographies across two major markets – Asia and Europe that comprised the majority of
							Sea Shipping and Logistics overall business. The company looked closely at how sales teams in these regions worked with their customers, defined growth
							milestones, and conducted customer analyses to pinpoint specific country challenges and potential.</p>
						<p>Sea Shipping and Logistics then began working to strengthen connectivity among their sales teams. The Bridge Code helped build a coordination center that
							tracks KPIs, shipment movements, and sales and pricing across geographies. Decisions in different parts of the world could now be made and communicated
							across the organization in real-time, rather than taking several days or weeks to coordinate as they traditionally had.</p>
						<p>After the first 16 months, the growth strategy had been systematically put in place. It featured major components such as below :</p>
					</div>

					<section className="steps steps-area">
						<div className="container">
							<ul className="timelines">
								<li className="timelines-box wow fadeInLeft">
									<i className="icon-layers"></i>
									<p className="timelines-title">1) Technology transformation :</p>
									<p className="timelines-details">
										The company worked with The Bridge Code to build automated management dashboards, sales dashboards and ECU360 uses data science to create a customer-centric
										experience.</p>
									<ul >
										<li className="py-1">
											<span className="timeline-point">a)</span> AI and machine learning incorporated into daily operations.
										</li>
										<li className="py-1">
											<span className="timeline-point">b)</span>Algorithms help manage container use, cargo flows, and sales productivity. This helps optimize procurement
											costs, warehousing, and workforce resources.
										</li>
										<li className="py-1">
											<span className="timeline-point">c)</span>The customer-facing sales dashboard is fully automated. The ECU360 platform automates all processes and
											back-end operations for customers. Getting price quotes, typically a 36-hours process, is now instant.
										</li>
									</ul>
									<span className="deee">1</span>
								</li>

								<li className="timelines-box wow fadeInRight">
									<i className="icon-chat"></i>
									<p className="timelines-title">2) A complete organizational redesign :</p>
									<p className="timelines-details">The Company had a detailed talent-value assessment identifying the key recruits they wanted for the organization to achieve the
										aspiration that they had set for themselves.</p>
									<span className="deee">2</span>
								</li>
								<li className="timelines-box  wow fadeInLeft">
									<i className="icon-tools"></i>
									<p className="timelines-title">3) Organizational Transformation</p>
									<p className="timelines-details">Tech Enablement and an organizational redesign boost sales 20%-40% and increased overall productivity by 1.5x</p>
									<span className="deee">3</span>
								</li>
								<li className="timelines-box wow fadeInRight">
									<i className="icon-layers"></i>
									<p className="timelines-title">4) Digital Enablement :</p>
									<p className="timelines-details">Digital and Analytics tools were put in place to optimize key processes, increasing door-to-door bookings by 25% and providing
										upfront tariff costs across 65+ countries.</p>
									<span className="deee">4</span>
								</li>
								<li className="timelines-box wow fadeInLeft">
									<i className="icon-chat"></i>
									<p className="timelines-title">5) Talent Growth :</p>
									<p className="timelines-details">The Organization was strengthened with 100+ new hires in 16 months, new skills playbooks, automated dashboard and institutionalized KPIs and governance.</p>
									<span className="deee">5</span>
								</li>
							</ul>
						</div>
					</section>

					<div>
						<h4 className="text-h4">The Impact :</h4>
						<p>The technology transformation created efficiencies across various areas of the business, improving Sea Shipping and Logistics resilience along with their
							visibility and control over cargo movements in the supply chain.</p>
						<p>The customer-facing digital sales dashboard, ECU360, brought more customer insights to Sea Shipping and Logistics, resulting in a 15 percent increase in
							adoption and 85 percent customer retention rate. A new sales app helped increase productivity for the client workforce with more than 60 percent of bookings
							now done on Mobile app. A yield management tool tracked value leakages and demand spikes, enabling teams to revamp sales strategies.</p>
						<p>While Supply chain & Logistics globally saw earnings growth during the pandemic due to demand bottlenecks, Sea Shipping and Logistics sales program was
							distinct in that it focused on volume growth specifically, which had been stagnant for several years. By focusing on key markets, Sea Shipping and Logistics
							transformation covered 60 percent of overall sales.</p>
						<p>Operations processing time was also reduced by 30 percent thanks to a model playbook developed and deployed across more than ten countries to streamline
							operations and set up an Sea Shipping and Logistics way of working. Getting this strategy right has enabled the company to start exploring its next bold moves
							in air freight and cross-border ecommerce.</p>
						<p>“Bringing focus and drive was a common goal we all aspired for. We’d grown through many acquisitions and needed a common way of doing business,” says CEO of
							Sea Shipping and Logistics. “Having brought The Bridge Code in as a part of the transformation has been a real partnership. They implemented the rigor needed to
							move the organization in the way it needed to move.”</p>
					</div>

					<div class="container-cs" id="case_study">
						<div class="circles-container">
							<div class="circles circleOne"></div>
							<div class="circles circleTwo"></div>
							<div class="circles circleThree"></div>
							<div class="circles circleFour"></div>
							<div class="logo">Supply Chain Case Study</div>
						</div>
						<svg width="1230" height="820" viewBox="0 0 1230 820" fill="none" xmlns="http://www.w3.org/2000/svg" class="mx-auto">
							<g opacity="0.15" class="line bottom bottomTwo">
								<path d="M615.5 661L615.5 497" stroke="#2378D5" stroke-width="12" />
							</g>
							<g opacity="0.15" class="line top topFour">
								<path d="M829 105L829 160.231C829 171.277 820.046 180.231 809 180.231L635 180.231C623.954 180.231 615 189.185 615 200.231L615 268" stroke="#2378D5" stroke-width="12" />
							</g>
							<g opacity="0.15" class="line top topThree">
								<path d="M615 104L615 268" stroke="#2378D5" stroke-width="12" />
							</g>
							<g opacity="0.15" class="line top topTwo">
								<path d="M402 106L402 160.769C402 171.815 410.954 180.769 422 180.769L595 180.769C606.046 180.769 615 189.724 615 200.769L615 268" stroke="#2378D5" stroke-width="12" />
							</g>
							<g opacity="0.15" class="line top topFive">
								<path d="M1041 105L1041 160.231C1041 171.276 1032.05 180.231 1021 180.231L635 180.231C623.954 180.231 615 189.185 615 200.231L615 268" stroke="#2378D5" stroke-width="12" />
							</g>
							<g opacity="0.15" class="line top topOne">
								<path d="M190 106L190 160.769C190 171.815 198.954 180.769 210 180.769L594.503 180.769C605.549 180.769 614.503 189.724 614.503 200.769L614.503 268" stroke="#2378D5" stroke-width="12" />
							</g>
							<g class="dots top dotsOne">
								<path d="M190 106L190 160.769C190 171.815 198.954 180.769 210 180.769L594.503 180.769C605.549 180.769 614.503 189.724 614.503 200.769L614.503 268" stroke="#2378D5" stroke-width="4" />
							</g>
							<g class="dots top dotsFive">
								<path d="M1041 105L1041 160.231C1041 171.276 1032.05 180.231 1021 180.231L635 180.231C623.954 180.231 615 189.185 615 200.231L615 268" stroke="#2378D5" stroke-width="4" />
							</g>
							<g class="dots top dotsTwo">
								<path d="M402 106L402 160.769C402 171.815 410.954 180.769 422 180.769L595 180.769C606.046 180.769 615 189.724 615 200.769L615 268" stroke="#2378D5" stroke-width="4" />
							</g>
							<g class="dots top dotsThree">
								<path d="M615 104L615 268" stroke="#2378D5" stroke-width="4" />
							</g>
							<g class="dots top dotsFour">
								<path d="M829 105L829 160.231C829 171.277 820.046 180.231 809 180.231L635 180.231C623.954 180.231 615 189.185 615 200.231L615 268" stroke="#2378D5" stroke-width="4" />
							</g>
							<g class="dots bottom dotsOne">
								<path d="M353.5 659.5L353.5 604.5C353.5 593.455 362.454 584.5 373.5 584.5L595 584.5C606.046 584.5 615 575.546 615 564.5L615 497" stroke="#2378D5" stroke-width="4" />
							</g>
							<g class="dots bottom dotsTwo">
								<path d="M615.5 661L615.5 497" stroke="#2378D5" stroke-width="4" />
							</g>
							<g class="dots bottom dotsThree">
								<path d="M877 659.5L877 604.5C877 593.454 868.046 584.5 857 584.5L635.5 584.5C624.454 584.5 615.5 575.545 615.5 564.5L615.5 497" stroke="#2378D5" stroke-width="4" />
							</g>
							<g filter="url(#filter0_d_484_1188)" class="block top topOne">
								<rect x="127" y="1" width="125" height="125" rx="6" fill="white" />
								<image x="141" y="23" width={95} xlinkHref="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/csd/python.png" />
								<rect x="126.5" y="0.5" width="126" height="126" rx="6.5" stroke="#ADADAD" stroke-opacity="0.6" />
							</g>
							<g filter="url(#filter1_d_484_1188)" class="block top topTwo">
								<rect x="339.25" y="1" width="125" height="125" rx="6" fill="white" />
								<image x="343" y="25" width={115} xlinkHref="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/csd/oracle.png" />
								<rect x="338.75" y="0.5" width="126" height="126" rx="6.5" stroke="#ADADAD" stroke-opacity="0.6" />
							</g>
							<g filter="url(#filter2_d_484_1188)" class="block top topThree">
								<rect x="551.5" y="1" width="125" height="125" rx="6" fill="white" />
								<image x="569" y="23" width={95} xlinkHref="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/csd/azure.png" />
								<rect x="551" y="0.5" width="126" height="126" rx="6.5" stroke="#ADADAD" stroke-opacity="0.6" />
							</g>
							<g filter="url(#filter3_d_484_1188)" class="block top topFour">
								<rect x="763.75" y="1" width="125" height="125" rx="6" fill="white" />
								<image x="779" y="23" width={95} xlinkHref="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/csd/javascript.png" />
								<rect x="763.25" y="0.5" width="126" height="126" rx="6.5" stroke="#ADADAD" stroke-opacity="0.6" />
							</g>
							<g filter="url(#filter4_d_484_1188)" class="block top topFive">
								<rect x="976" y="1" width="125" height="125" rx="6" fill="white" />
								<image x="986" y="18" width={108} xlinkHref="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/nodejs.png" />
								<rect x="975.5" y="0.5" width="126" height="126" rx="6.5" stroke="#ADADAD" stroke-opacity="0.6" />
							</g>
							<g filter="url(#filter6_d_484_1188)" class="block bottom bottomTwo">
								<rect x="525" y="640" width="180" height="176" rx="6" fill="white" />
								<image x="528" y="675" width={175} xlinkHref="https://res.cloudinary.com/dm1muiikf/image/upload/v1690872419/website/case_study/shipping.png" />
								<rect x="524.5" y="639.5" width="181" height="177" rx="6.5" stroke="#ADADAD" stroke-opacity="0.6" />
							</g>
							<defs>
								<filter id="filter0_d_484_1188" x="126" y="0" width="130" height="130" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
									<feFlood flood-opacity="0" result="BackgroundImageFix" />
									<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
									<feOffset dx="3" dy="3" />
									<feColorMatrix type="matrix" values="0 0 0 0 0.0340278 0 0 0 0 0.157404 0 0 0 0 0.291667 0 0 0 0.15 0" />
									<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_484_1188" />
									<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_484_1188" result="shape" />
								</filter>
								<filter id="filter1_d_484_1188" x="338.25" y="0" width="130" height="130" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
									<feFlood flood-opacity="0" result="BackgroundImageFix" />
									<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
									<feOffset dx="3" dy="3" />
									<feColorMatrix type="matrix" values="0 0 0 0 0.0340278 0 0 0 0 0.157404 0 0 0 0 0.291667 0 0 0 0.15 0" />
									<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_484_1188" />
									<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_484_1188" result="shape" />
								</filter>
								<filter id="filter2_d_484_1188" x="550.5" y="0" width="130" height="130" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
									<feFlood flood-opacity="0" result="BackgroundImageFix" />
									<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
									<feOffset dx="3" dy="3" />
									<feColorMatrix type="matrix" values="0 0 0 0 0.0340278 0 0 0 0 0.157404 0 0 0 0 0.291667 0 0 0 0.15 0" />
									<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_484_1188" />
									<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_484_1188" result="shape" />
								</filter>
								<filter id="filter3_d_484_1188" x="762.75" y="0" width="130" height="130" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
									<feFlood flood-opacity="0" result="BackgroundImageFix" />
									<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
									<feOffset dx="3" dy="3" />
									<feColorMatrix type="matrix" values="0 0 0 0 0.0340278 0 0 0 0 0.157404 0 0 0 0 0.291667 0 0 0 0.15 0" />
									<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_484_1188" />
									<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_484_1188" result="shape" />
								</filter>
								<filter id="filter4_d_484_1188" x="975" y="0" width="130" height="130" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
									<feFlood flood-opacity="0" result="BackgroundImageFix" />
									<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
									<feOffset dx="3" dy="3" />
									<feColorMatrix type="matrix" values="0 0 0 0 0.0340278 0 0 0 0 0.157404 0 0 0 0 0.291667 0 0 0 0.15 0" />
									<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_484_1188" />
									<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_484_1188" result="shape" />
								</filter>
								<filter id="filter5_d_484_1188" x="259" y="639" width="185" height="181" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
									<feFlood flood-opacity="0" result="BackgroundImageFix" />
									<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
									<feOffset dx="3" dy="3" />
									<feColorMatrix type="matrix" values="0 0 0 0 0.0340278 0 0 0 0 0.157404 0 0 0 0 0.291667 0 0 0 0.15 0" />
									<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_484_1188" />
									<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_484_1188" result="shape" />
								</filter>
								<filter id="filter6_d_484_1188" x="524" y="639" width="185" height="181" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
									<feFlood flood-opacity="0" result="BackgroundImageFix" />
									<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
									<feOffset dx="3" dy="3" />
									<feColorMatrix type="matrix" values="0 0 0 0 0.0340278 0 0 0 0 0.157404 0 0 0 0 0.291667 0 0 0 0.15 0" />
									<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_484_1188" />
									<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_484_1188" result="shape" />
								</filter>
								<filter id="filter7_d_484_1188" x="789" y="639" width="185" height="181" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
									<feFlood flood-opacity="0" result="BackgroundImageFix" />
									<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
									<feOffset dx="3" dy="3" />
									<feColorMatrix type="matrix" values="0 0 0 0 0.0340278 0 0 0 0 0.157404 0 0 0 0 0.291667 0 0 0 0.15 0" />
									<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_484_1188" />
									<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_484_1188" result="shape" />
								</filter>
							</defs>
						</svg>
					</div>

					{/* **********************  Mobile view casestudy ********************** */}
					<div class="" id="casestudy">
						<div id="demo">
							<svg viewBox="0 0 630 1630" fill="none" xmlns="http://www.w3.org/2000/svg" class="mt-5">
								<g opacity="0.15" class="line bottom bottomTwo">
									<path d="M315 104L315 1468" stroke="#2378D5" stroke-width="12" />
								</g>
								<g opacity="0.15" class="line top topTwo">
									<path d="M315 104L315 1468" stroke="#2378D5" stroke-width="12" />
								</g>
								<g class="dots top dotsTwo">
									<path d="M315 104L315 468" stroke="#2378D5" stroke-width="4" />
								</g>
								<g class="dots top dotsThree">
									<path d="M315 104L315 468" stroke="#2378D5" stroke-width="4" />
								</g>
								<g class="dots top dotsFour">
									<path d="M315 104L315 468" stroke="#2378D5" stroke-width="4" />
								</g>
								<g class="dots bottom dotsOne">
									<path d="M315 661L315.5 1297" stroke="#2378D5" stroke-width="4" />
								</g>
								<g class="dots bottom dotsTwo">
									<path d="M315 661L315.5 1297" stroke="#2378D5" stroke-width="4" />
								</g>
								<g class="dots bottom dotsThree">
									<path d="M315 661L315.5 1297" stroke="#2378D5" stroke-width="4" />
								</g>
								<g class="block top topOne">
									<rect x="250" y="1" width="125" height="125" rx="6" fill="white" />
									<image x="259" y="25" width={101} xlinkHref="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/csd/python.png" />
									<rect x="250.5" y="0.5" width="126" height="126" rx="6.5" stroke="#ADADAD" stroke-opacity="0.6" />
								</g>
								<g class="block top topTwo">
									<rect x="250" y="228" width="125" height="125" rx="6" fill="white" />
									<image x="260" y="245" width={115} xlinkHref="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/csd/oracle.png" />
									<rect x="250.5" y="228.5" width="126" height="126" rx="6.5" stroke="#ADADAD" stroke-opacity="0.6" />
								</g>
								<g class="block top topThree">
									<rect x="250" y="455" width="125" height="125" rx="6" fill="white" />
									<image x="255" y="459" width={105} xlinkHref="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/csd/azure.png" />
									<rect x="250.5" y="455.5" width="126" height="126" rx="6.5" stroke="#ADADAD" stroke-opacity="0.6" />
								</g>
								<g class="block top topFour">
									<rect x="250" y="682" width="125" height="125" rx="6" fill="white" />
									<image x="255" y="690" width={115} xlinkHref="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/csd/javascript.png" />
									<rect x="250.25" y="682.5" width="126" height="126" rx="6.5" stroke="#ADADAD" stroke-opacity="0.6" />
								</g>
								<g class="block top topFive">
									<rect x="250" y="909" width="125" height="125" rx="6" fill="white" />
									<image x="266" y="925" width={105} xlinkHref="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/nodejs.png" />
									<rect x="250.5" y="909.5" width="126" height="126" rx="6.5" stroke="#ADADAD" stroke-opacity="0.6" />
								</g>

								<svg id="svg" viewBox="0 0 320 1" width="71%" >
									<circle class="background" cx="220" cy="290" r="35" stroke="" fill="#0051AA" />
									<circle class="background" cx="220" cy="290" r="75" stroke="" fill="#B3D1EF" />
									<circle class="background" cx="220" cy="290" r="55" stroke="" fill="#0051AA" />
									<foreignObject x="165" y="255" width="100" height="100">
										<div className="fs-6 text-white text-center">
											Supplychain Case Study
										</div>
									</foreignObject>
									<circle class="background" cx="220" cy="290" r="95" stroke="" fill="rgba(0, 74, 171, 0.1)" />
								</svg>

								<g class="block bottom bottomTwo">
									<rect x="220" y="1436" width="180" height="176" rx="6" fill="white" />
									<image x="229" y="1457" width={185} xlinkHref="https://res.cloudinary.com/dm1muiikf/image/upload/v1690872419/website/case_study/shipping.png" />
									<rect x="220.5" y="1436.5" width="181" height="177" rx="6.5" stroke="#ADADAD" stroke-opacity="0.6" />
								</g>
								<defs>
									<filter id="filter0_d_484_1188" x="126" y="0" width="130" height="130" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
										<feFlood flood-opacity="0" result="BackgroundImageFix" />
										<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
										<feOffset dx="3" dy="3" />
										<feColorMatrix type="matrix" values="0 0 0 0 0.0340278 0 0 0 0 0.157404 0 0 0 0 0.291667 0 0 0 0.15 0" />
										<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_484_1188" />
										<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_484_1188" result="shape" />
									</filter>
									<filter id="filter1_d_484_1188" x="338.25" y="0" width="130" height="130" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
										<feFlood flood-opacity="0" result="BackgroundImageFix" />
										<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
										<feOffset dx="3" dy="3" />
										<feColorMatrix type="matrix" values="0 0 0 0 0.0340278 0 0 0 0 0.157404 0 0 0 0 0.291667 0 0 0 0.15 0" />
										<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_484_1188" />
										<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_484_1188" result="shape" />
									</filter>
									<filter id="filter2_d_484_1188" x="550.5" y="0" width="130" height="130" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
										<feFlood flood-opacity="0" result="BackgroundImageFix" />
										<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
										<feOffset dx="3" dy="3" />
										<feColorMatrix type="matrix" values="0 0 0 0 0.0340278 0 0 0 0 0.157404 0 0 0 0 0.291667 0 0 0 0.15 0" />
										<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_484_1188" />
										<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_484_1188" result="shape" />
									</filter>
									<filter id="filter3_d_484_1188" x="762.75" y="0" width="130" height="130" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
										<feFlood flood-opacity="0" result="BackgroundImageFix" />
										<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
										<feOffset dx="3" dy="3" />
										<feColorMatrix type="matrix" values="0 0 0 0 0.0340278 0 0 0 0 0.157404 0 0 0 0 0.291667 0 0 0 0.15 0" />
										<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_484_1188" />
										<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_484_1188" result="shape" />
									</filter>
									<filter id="filter4_d_484_1188" x="975" y="0" width="130" height="130" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
										<feFlood flood-opacity="0" result="BackgroundImageFix" />
										<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
										<feOffset dx="3" dy="3" />
										<feColorMatrix type="matrix" values="0 0 0 0 0.0340278 0 0 0 0 0.157404 0 0 0 0 0.291667 0 0 0 0.15 0" />
										<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_484_1188" />
										<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_484_1188" result="shape" />
									</filter>
									<filter id="filter5_d_484_1188" x="259" y="639" width="185" height="181" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
										<feFlood flood-opacity="0" result="BackgroundImageFix" />
										<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
										<feOffset dx="3" dy="3" />
										<feColorMatrix type="matrix" values="0 0 0 0 0.0340278 0 0 0 0 0.157404 0 0 0 0 0.291667 0 0 0 0.15 0" />
										<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_484_1188" />
										<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_484_1188" result="shape" />
									</filter>
									<filter id="filter6_d_484_1188" x="524" y="639" width="185" height="181" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
										<feFlood flood-opacity="0" result="BackgroundImageFix" />
										<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
										<feOffset dx="3" dy="3" />
										<feColorMatrix type="matrix" values="0 0 0 0 0.0340278 0 0 0 0 0.157404 0 0 0 0 0.291667 0 0 0 0.15 0" />
										<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_484_1188" />
										<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_484_1188" result="shape" />
									</filter>
									<filter id="filter7_d_484_1188" x="789" y="639" width="185" height="181" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
										<feFlood flood-opacity="0" result="BackgroundImageFix" />
										<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
										<feOffset dx="3" dy="3" />
										<feColorMatrix type="matrix" values="0 0 0 0 0.0340278 0 0 0 0 0.157404 0 0 0 0 0.291667 0 0 0 0.15 0" />
										<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_484_1188" />
										<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_484_1188" result="shape" />
									</filter>
								</defs>
							</svg>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default SupplyChain;