import React, { useState, useEffect } from "react";
import API from '../../../middleware/API';
import { FaInstagram, FaSkype, FaLinkedin, FaWhatsapp, FaFacebook, FaTwitter, FaArrowAltCircleRight, FaCompress, FaChevronLeft, FaChevronRight } from "react-icons/fa";
import moment from 'moment';
import { Modal } from 'react-bootstrap';
import { Carousel } from 'react-responsive-carousel';
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useLocation } from 'react-router-dom';

const responsive = {
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3,
        slidesToSlide: 4 // optional, default to 1.
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
        slidesToSlide: 2 // optional, default to 1.
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
        slidesToSlide: 1 // optional, default to 1.
    }
};

// ================================|| Blog ||================================ //

const Blog = () => {
    var slug = '';
    const location = useLocation();
    const [Blogdata, setBlogData] = useState();
    const [PrevBlogdata, setPrevBlogData] = useState();
    const [NextBlogdata, setNextBlogData] = useState();
    const [BlogCommentdata, setBlogCommentData] = useState();
    const [fname, setFName] = useState("");
    const [lname, setLName] = useState("");
    const [email, setEmail] = useState("");
    const [textmessage, setTextmessage] = useState("");
    const [message, setMessage] = useState("");
    const [Show, setShow] = useState(false);
    const handleClose = () => setShow(false);               // Open model close on click on a close button.
    const handleShow = () => setShow(true);                 // On button click Open the model.

    const [isFirstLoad, setIsFirstLoad] = useState(true)
    const [isVisible, setVisible] = useState(false)


    useEffect(() => {
        slug = location.pathname.replace('/blog/', '');
        getBlogByBlogSlug();
        if (!isFirstLoad) {
            window.location.reload();
        } else {
            setIsFirstLoad(false)
        }
    }, [isVisible]);

    let submitHandler = async (e) => {
        e.preventDefault();
        try {
            var SaveBlogCommentsAPI = API.saveBlogComments;
            let res = await fetch(SaveBlogCommentsAPI, {
                method: "POST",
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                },
                json: true,
                body: JSON.stringify({
                    blogId: Blogdata[0].Id,
                    firstName: fname,
                    lastName: lname,
                    email: email,
                    description: textmessage
                }),
            });
            if (res.status === 200) {
                handleShow();
                setFName("");
                setLName("");
                setEmail("");
                setTextmessage("");
                setMessage("Your response has been submitted sucessfully.");
            }
        } catch (error) {
            console.log(error);
        }
    };

    const getBlogByBlogSlug = async () => {
        try {
            var GetBlogByBlogSlugAPI = API.getBlogByBlogSlug;
            GetBlogByBlogSlugAPI = GetBlogByBlogSlugAPI + "?blogSlug=" + slug;
            const response = await fetch(GetBlogByBlogSlugAPI);
            const jsonData = await response.json();
            setBlogData(jsonData.recordsets[0]);
            console.log(jsonData.recordsets[0]);
            if (jsonData.recordsets[1][0].PrevSlug != "")
                setPrevBlogData(jsonData.recordsets[1][0].PrevSlug);
            if (jsonData.recordsets[1][0].NextSlug != "")
                setNextBlogData(jsonData.recordsets[1][0].NextSlug);
            console.log(Blogdata);

            var GetBlogCommentsByBlogIdAPI = API.getBlogCommentsByBlogId;
            GetBlogCommentsByBlogIdAPI = GetBlogCommentsByBlogIdAPI + "?blogId=" + jsonData.recordsets[0][0].Id;
            const response1 = await fetch(GetBlogCommentsByBlogIdAPI);
            const jsonData1 = await response1.json();
            setBlogCommentData(jsonData1.recordsets[0]);
            console.log(BlogCommentdata);
        }
        catch (error) {
            console.log(error);
        }
    };

    return (
        <>
            <Modal show={Show} style={{ marginTop: "300px" }}>
                <Modal.Header>
                    <Modal.Title style={{ fontSize: "1.3rem" }}>
                        Your response has been submitted sucessfully.<br />
                        <input type="submit" value="Ok" style={{ width: "fit-content", marginLeft: "200px" }} onClick={handleClose} className="cl-button -primary -small rounded-2 py-2" />
                    </Modal.Title>
                </Modal.Header>
            </Modal>
            {Blogdata &&
                Blogdata.map(({ SeoTitle, BlogSlug, Meta_Description }) => (
                    <Helmet>
                        {document.title = SeoTitle}
                        {document.head.querySelector('meta[name="title"]').content = SeoTitle}
                        {document.head.querySelector('meta[name="description"]').content = Meta_Description}
                        {document.head.querySelector('link[rel="canonical"]').content = "https://www.devotionbusiness.com/blog/" + BlogSlug}
                        {document.head.querySelector('meta[property="og:title"]').content = SeoTitle}
                        {document.head.querySelector('meta[property="og:url"]').content = "https://www.devotionbusiness.com/blog/" + BlogSlug}
                        {document.head.querySelector('meta[property="og:description"]').content = Meta_Description}
                        {document.head.querySelector('meta[property="og:image"]').content = "https://res.cloudinary.com/dm1muiikf/image/upload/v1702367024/website/TBC_LOGO.png"}
                    </Helmet>
                ))}
            <div className='container-fluid p-0'>
                <div class="position-relative overflow-hidden text-center about">
                    <div class="col-md-5 p-lg-5 mx-auto my-1">
                        <h1 class="display-2 fw-bolder text-white title-c">Blog Description</h1>
                        <p class="lead fw-normal text-light"></p>
                        <Link to='/contact-us' class="cl-button -primary -big rounded-3">Contact Us</Link>
                    </div>
                </div>

                <div className="container-fluid py-2 blog_dec ">
                    {Blogdata &&
                        Blogdata.map(({ Id, BlogTitle, Category, BlogTages, BlogImage, BlogDescription, CreatedOn, CreatedBy, Profile }) => (
                            <div className="col-md-12 row ">
                                <div className="col-md-2 row mx-auto">
                                    <ul className="text-center align-items-center my-5 list-group" id='b_page' style={{ listStyle: "none", textAlign: "end" }}>
                                        <li className=' list-group-item" my-2 py-3 px-2'><a href="https://www.instagram.com/thebridgecode/" className="d-inline border p-3 border shadow bg-light rounded-3" aria-label="Footer"><FaInstagram size={'2.5rem'} /></a></li>
                                        <li className=' list-group-item" my-2 py-3 px-2'><a href="https://join.skype.com/invite/dqpa9PFcZ1aI" className="d-inline border p-3 border shadow bg-light rounded-3" aria-label="Footer"><FaSkype size={'2.5rem'} /></a></li>
                                        <li className=' list-group-item" my-2 py-3 px-2'><a href="https://www.linkedin.com/company/the-bridge-code/" className="d-inline border p-3 border shadow bg-light rounded-3" aria-label="Footer"><FaLinkedin size={'2.5rem'} /></a></li>
                                        <li className=' list-group-item" my-2 py-3 px-2'><a href="https://wa.me/message/2WJS2ZS4J2INL1" className="d-inline border p-3 border shadow bg-light rounded-3" aria-label="Footer"><FaWhatsapp size={'2.5rem'} /></a></li>
                                        <li className=' list-group-item" my-2 py-3 px-2'><a href="https://www.facebook.com/thebridgecode/" className="d-inline border p-3 border shadow bg-light rounded-3" aria-label="Footer"><FaFacebook size={'2.5rem'} /></a></li>
                                        <li className=' list-group-item" my-2 py-3 px-2'><a href="https://twitter.com/thebridgecode" className="d-inline border p-3 border shadow bg-light rounded-3" aria-label="Footer"><FaTwitter size={'2.5rem'} /></a></li>
                                    </ul>

                                    {/* Mobile responsive */}
                                    <ul className="d-flex justify-content-between" id='b_mobile' style={{ listStyle: "none", textAlign: "end", flexWrap: "wrap" }}>
                                        {/* <li className=' list-group-item" my-2 py-3 px-2'><a href="https://www.instagram.com/thebridgecode/" className="d-inline border p-2 border shadow bg-light rounded-3" aria-label="Footer"><FaInstagram size={'1.5rem'} /></a></li>
                                        <li className=' list-group-item" my-2 py-3 px-2'><a href="https://join.skype.com/invite/dqpa9PFcZ1aI" className="d-inline border p-2 border shadow bg-light rounded-3" aria-label="Footer"><FaSkype size={'1.5rem'} /></a></li>
                                        <li className=' list-group-item" my-2 py-3 px-2'><a href="https://www.linkedin.com/company/the-bridge-code/" className="d-inline border p-2 border shadow bg-light rounded-3" aria-label="Footer"><FaLinkedin size={'1.5rem'} /></a></li>
                                        <li className=' list-group-item" my-2 py-3 px-2'><a href="https://wa.me/message/2WJS2ZS4J2INL1" className="d-inline border p-2 border shadow bg-light rounded-3" aria-label="Footer"><FaWhatsapp size={'1.5rem'} /></a></li>
                                        <li className=' list-group-item" my-2 py-3 px-2'><a href="https://www.facebook.com/thebridgecode/" className="d-inline border p-2 border shadow bg-light rounded-3" aria-label="Footer"><FaFacebook size={'1.5rem'} /></a></li>
                                        <li className=' list-group-item" my-2 py-3 px-2'><a href="https://twitter.com/thebridgecode" className="d-inline border p-2 border shadow bg-light rounded-3" aria-label="Footer"><FaTwitter size={'1.5rem'} /></a></li> */}
                                    </ul>
                                </div>

                                <div className="col-md-6 row">
                                    <div className="text-center display-3 pt-3 py-4">{BlogTitle}</div>
                                    <div className="blog-post-header-info">
                                        <div className="blog-post-header-authors align-item-center text-center p-0">
                                            <img src={Profile} className="w-px-40 h-auto rounded-circle" />
                                            <p className="fs-6 m-0 fw-bold">By: {CreatedBy}</p>
                                            <div className="fs-6 py-3 fw-bold" style={{ textAlign: "end" }}>Date: {moment(CreatedOn).format('DD-MM-YYYY')}</div>
                                        </div>
                                    </div>
                                    <div className="fw-bold display-6 py-3">{BlogTitle}</div>
                                    <div className="align-item-center text-center py-3"><img src={BlogImage} className="img-fluid" /></div>
                                    <div className="text-decoration-none py-3 img-fluid" dangerouslySetInnerHTML={{ __html: BlogDescription }} ></div>
                                    <p className="fs-6 m-0 fw-bold pt-4">Category: {Category}</p>
                                    <p className="fs-6 m-0 fw-bold pb-4">Tags: {BlogTages}</p>
                                    <div className="blog-post-header-info">
                                        <div className="blog-post-header-authors p-0" style={{ display: "flex" }}>
                                            <div className="col-6">{PrevBlogdata ? <a href={"https://thebridgecode.com/blog/" + PrevBlogdata} className="w-50 fs-6 fw-bold text-decoration-none text-dark"><FaChevronLeft /> Previous</a> : null}</div>
                                            <div className="col-6 text-end">{NextBlogdata ? <a href={"https://thebridgecode.com/blog/" + NextBlogdata} className="w-50 fs-6 fw-bold text-decoration-none text-dark">Next <FaChevronRight /></a> : null}</div>
                                        </div>
                                    </div>

                                    {/* <!-- End About Section --> */}
                                    <div className="container mt-4 mb-5">
                                        <div className="row col-md-12">
                                            <Carousel
                                                swipeable={true}
                                                draggable={false}
                                                showDots={true}
                                                responsive={responsive}
                                                ssr={true} // means to render carousel on server-side.
                                                infiniteLoop='true'
                                                autoPlay
                                                autoPlaySpeed={2000}
                                                dynamicHeight={true}
                                                keyBoardControl={true}
                                                showArrows={false}
                                                customTransition="all .5"
                                                transitionDuration={300}
                                                containerClass="carousel-container"
                                                removeArrowOnDeviceType={["computer", "tablet", "mobile"]}
                                            >

                                                {BlogCommentdata &&
                                                    BlogCommentdata.map(({ FirstName, LastName, Description, CreatedOn }) => (
                                                        <div className="card_id p-5">
                                                            <div style={{ textAlign: "left" }}>
                                                                <div><img src="" /></div>
                                                                <p className="text-left"><span className="fw-bold">{FirstName} {LastName}</span> made a post on {moment(CreatedOn).format('DD-MMMM-YYYY')}</p>
                                                                <p><span className="fw-bolder fs-5">"</span> {Description} <span className="fw-bolder fs-5">"</span></p>
                                                            </div>
                                                        </div>
                                                    ))}

                                            </Carousel>
                                        </div>
                                    </div>
                                    <div className="col-md-10 p-lg-5 mx-auto mt-2 border rounded-3 shadow-sm">
                                        <p className='display-5 fw-bold'>Comment</p>
                                        <form className="mb-5" id="inquiry-form" onSubmit={submitHandler}>
                                            <div className='row'>
                                                <div className=" col-md-6 form-item pb-4">
                                                    <input type="text" name="fname" placeholder="First Name" value={fname} onChange={(e) => setFName(e.target.value)} required />
                                                </div>
                                                <div className=" col-md-6 form-item pb-4">
                                                    <input type="text" name="lname" placeholder="Last Name" value={lname} onChange={(e) => setLName(e.target.value)} required />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12 form-group py-2">
                                                    <input type="email" name="email" id="email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} required />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12 form-group py-2">
                                                    <textarea name="message" id="message" cols="30" rows="7" placeholder="Message" row="7" value={textmessage} onChange={(e) => setTextmessage(e.target.value)} ></textarea>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12 py-1 d-block">
                                                    <input type="submit" value="Send Email" className="cl-button -primary -small rounded-2 py-2" />
                                                </div>
                                            </div>
                                            <div className="message">{message ? <p>{message}</p> : null}</div>
                                        </form>
                                    </div>
                                </div>

                                <div className="col-md-3 row mx-auto d-inline align-middle ">
                                    <p className="fs-1 fw-bold text-center pb-3 title-c">Our Case Study</p>
                                    <ul className="pb-4" style={{ listStyle: "none" }}>
                                        <li className="fw-bold"><Link to='/case_study/supplychain' className="fs-4 pb-1 text-decoration-none text-sky" target="_blank"><FaCompress className="pe-2" /> Supplychain</Link></li>
                                        <li className="fw-bold"><Link to='/case_study/travel' className="fs-4 pb-1 text-decoration-none text-sky" target="_blank"><FaCompress className="pe-2" /> Travel & Hospitality</Link></li>
                                        <li className="fw-bold"><Link to='/case_study/hitech' className="fs-4 pb-1 text-decoration-none text-sky" target="_blank"><FaCompress className="pe-2" /> Hitech</Link></li>
                                        <li className="fw-bold"><Link to='/case_study/healthcare' className="fs-4 pb-1 text-decoration-none text-sky" target="_blank"><FaCompress className="pe-2" /> Healthcare & Life Science</Link></li>
                                        <li className="fw-bold"><Link to='/case_study/digital_retail' className="fs-4 pb-1 text-decoration-none text-sky" target="_blank"><FaCompress className="pe-2" /> Digital Retail</Link></li>
                                        <li className="fw-bold"><Link to='/case_study/fintech' className="fs-4 pb-1 text-decoration-none text-sky" target="_blank"><FaCompress className="pe-2" /> Fintech</Link></li>
                                        <li className="fw-bold"><Link to='/case_study/media_advertising' className="fs-4 pb-1 text-decoration-none text-sky" target="_blank"><FaCompress className="pe-2" /> Media & Advertising</Link></li>
                                    </ul>
                                    <div className="col-md-12 text-center p-lg-5 p-5 mx-auto text-dark bg-body shadow-lg rounded-5">
                                        <p className='fs-1 fw-bold'>Why The Bridge Code?</p>
                                        <p className='fs-6 pb-2'>The Bridge Code Technologies is a Firm where we provide software development, integrations, and resources you need to
                                            compete in the Fast changing world. We believe in the power of marketing, sales, content management and great customer service are key to success
                                            in any Business. We have started a New Approach for our Clients where they can see the Potential of our Skills and Software. Try our Free Demo!</p>
                                        <div className="mt-4">
                                            <Link to='/solution' className="cl-button -primary -small mx-1 rounded-3">Try Free Demo <FaArrowAltCircleRight className='blink' size={20} style={{ color: "" }} /></Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                </div>
            </div>
        </>
    );
};

export default Blog;